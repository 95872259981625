import React from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import Well from '@react/react-spectrum/Well'
import { error } from '@react/react-spectrum/Toast'
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger'
import Tooltip from '@react/react-spectrum/Tooltip'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import TextField from '@react/react-spectrum/Textfield'
import Button from '@react/react-spectrum/Button'
import Delete from '@react/react-spectrum/Icon/Delete'

export default function SenseiParams ({
    posClicks,
    setPosClicks,
    negClicks,
    setNegClicks,
    name
}) {
    function deleteCoord (type, coordID) {
        if (type === '+') {
            setPosClicks(coords => {
                const newCoords = coords.filter(coord => coord.id !== coordID)
                return newCoords
            })
        } else {
            setNegClicks(coords => coords.filter(coord => coord.id !== coordID))
        }
    }

    function coordPair (type, coord, idx) {
        const label = type === '+' ? `${__('positive_click')}` : `${__('negative_click')}`
        return <React.Fragment key={idx}>
            <Well>
                <div className="adobe-skycity-options" title={`ID: ${coord.id}`}>
                    <div className="adobe-skycity-option">
                        <FieldLabel className="adobe-skycity-options-field" label={label} labelFor="idInputDimension" position="left">
                        </FieldLabel>
                    </div>

                    <div className="adobe-skycity-option">
                        <FieldLabel className="adobe-skycity-options-field" label="x coord" labelFor="idInputDimension" position="left">
                            <OverlayTrigger placement="right">
                                <TextField
                                    label="x"
                                    defaultValue={coord.x}
                                    disabled={true}
                                    inputMode="numeric"
                                    id="idInputDimension"
                                />
                                <Tooltip variant="info">
                                    {__('output_option_tooltip_inputdimension_depthestimation')}
                                </Tooltip>
                            </OverlayTrigger>
                        </FieldLabel>
                    </div>
                    <div className="adobe-skycity-option">
                        <FieldLabel className="adobe-skycity-options-field" label="y coord" labelFor="idInputDimension" position="left">
                            <OverlayTrigger placement="right">
                                <TextField
                                    label="y"
                                    defaultValue={coord.y}
                                    disabled={true}
                                    inputMode="numeric"
                                    id="idInputDimension"
                                />
                                <Tooltip variant="info">
                                    {__('output_option_tooltip_inputdimension_depthestimation')}
                                </Tooltip>
                            </OverlayTrigger>
                        </FieldLabel>
                    </div>
                    <div className="adobe-skycity-output-option-delete-button">
                        <Button variant="tool" icon={<Delete size="XS"/>} onClick={ e => deleteCoord(type, coord.id) }/>
                    </div>
                </div>
            </Well>
        </React.Fragment>
    }

    try {
        return <Well>
            <React.Fragment>
                <Well>
                    <div className="adobe-skycity-options-col">
                        {posClicks.map((coord, idx) => coordPair('+', coord, idx))}
                        {negClicks.map((coord, idx) => coordPair('-', coord, idx))}
                    </div>
                </Well>
            </React.Fragment>
        </Well>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
