import React, { createContext, useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import axios from 'axios'
import { SkycityContext } from '../../lib/SkycityProvider'
import { error, success, info } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'
import Progress from '@react/react-spectrum/Progress'
import Well from '@react/react-spectrum/Well'
import Download from '@react/react-spectrum/Icon/Download'
import { Image } from '@react/react-spectrum/Image'
import StatusLight from '@react/react-spectrum/StatusLight'
import Heading from '@react/react-spectrum/Heading'
import Link from '@react/react-spectrum/Link'
import ModalTrigger from '@react/react-spectrum/ModalTrigger'
import Dialog from '@react/react-spectrum/Dialog'
import { TableView } from '@react/react-spectrum/TableView'
import { Asset } from '@react/react-spectrum/Asset'
import Checkbox from '@react/react-spectrum/Checkbox'
import Radio from '@react/react-spectrum/Radio'
import RadioGroup from '@react/react-spectrum/RadioGroup'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import imgPsd from '../../asset/imgPsd.png'
import { apiCall, getStatus } from '../../lib/cisApi.js'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import {
    getFilename,
    getFilenameWithoutExt,
    getThumbnail,
    getOutputToDownload,
    downloadScript,
    copyText,
    downloadFile,
    getStorage,
    getRendition,
    getStorageTypes,
    getExtensionFromPath,
    getLocalStorageItem,
    setLocalStorageItem
} from '../../lib/skycity.js'

import { getConfig as psDocumentManifestConfig, getTestCases as psDocumentManifestApiTestCases } from '../../asset/code/psDocumentManifest.js'
import { getConfig as psInsertLayerConfig, getTestCases as psInsertLayerApiTestCases } from '../../asset/code/psInsertLayer.js'
import { getConfig as psFileConversionConfig, getTestCases as psFileConversionApiTestCases } from '../../asset/code/psFileConversion.js'
import { getConfig as psActionsConfig, getTestCases as psActionsApiTestCases } from '../../asset/code/psActions.js'
import { getConfig as psEditConfig, getTestCases as psEditApiTestCases } from '../../asset/code/psEdit.js'
import { getConfig as psSmartObjectV2Config, getTestCases as psSmartObjectV2ApiTestCases } from '../../asset/code/psSmartObjectV2.js'
import { getConfig as ssImagecutoutConfig, getTestCases as ssImagecutoutApiTestCases } from '../../asset/code/ssImagecutout.js'
// import { EnvironmentCredentials } from 'aws-sdk'
// import { getConfig as psTest } from "../../asset/code/psTest.js"

export default function Operation ({
    files,
    language,
    style = atomOneDark,
    lineNumberStyle = { color: 'rgba(112, 112, 112, 0.8)' },
    showLineNumbers = true
}) {
    const {
        errorOptions,
        testResultDs,
        apiTestCases,
        apiTestTypes,
        storageTypes,
        dispatch
    } = useContext(SkycityContext)

    const sdk = require('@adobe/aio-lib-photoshop-api')
    const [testCaseCount, setTestCaseCount] = useState(0)
    let psConfigs = []

    const ToolTestContext = createContext()
    const ToolTestContextProvider = ({ children }) => {
        return (
            <ToolTestContext.Provider>
                {children}
            </ToolTestContext.Provider>
        )
    }

    async function getCases () {
        // let testCases
        psConfigs = []
        const sTypes = getLocalStorageItem('storageTypes') || setLocalStorageItem('storageTypes', storageTypes)
        const testMatrix = [
            {
                testCaseName: 'psDocumentManifest',
                testCases: await psDocumentManifestApiTestCases(getStorageTypes(sTypes))
            },
            {
                testCaseName: 'psEdit',
                testCases: await psEditApiTestCases(getStorageTypes(sTypes))
            },
            {
                testCaseName: 'psInsertLayer',
                testCases: await psInsertLayerApiTestCases(getStorageTypes(sTypes))
            },
            {
                testCaseName: 'psFileConversion',
                testCases: await psFileConversionApiTestCases(getStorageTypes(sTypes))
            },
            {
                testCaseName: 'psActions',
                testCases: await psActionsApiTestCases(getStorageTypes(sTypes))
            },
            {
                testCaseName: 'psSmartObjectV2',
                testCases: await psSmartObjectV2ApiTestCases(getStorageTypes(sTypes))
            },
            {
                testCaseName: 'ssImagecutout',
                testCases: await ssImagecutoutApiTestCases(getStorageTypes(sTypes))
            }
        ]
        testMatrix.forEach(matrix => {
            if (isRun(matrix.testCaseName)) {
                // testCases = matrix.testCases
                if (whichTestType() === 'smokeTest') {
                    matrix.testCases.length = 1
                }
                matrix.testCases.forEach(async testCase => {
                    let testConfig = ''
                    switch (matrix.testCaseName) {
                    case 'psDocumentManifest':
                        testConfig = psDocumentManifestConfig(testCase.body.inputs[0])
                        testConfig.body = JSON.stringify(testConfig.payload)
                        psConfigs.push({
                            inputFile: testCase.body.inputs,
                            config: testConfig,
                            testCase: testCase
                        })
                        break
                    case 'psInsertLayer':
                        testConfig = psInsertLayerConfig(testCase.body.inputs[0], testCase.body.outputs, null, testCase.body.options.layers)
                        testConfig.payload.outputs = testCase.body.outputs
                        testConfig.body = JSON.stringify(testConfig.payload)
                        psConfigs.push({
                            inputFile: testCase.body.inputs,
                            config: testConfig,
                            testCase: testCase
                        })
                        break
                    case 'psEdit':
                        testConfig = psEditConfig(testCase.body.inputs[0], testCase.body.outputs, null, testCase.body.options)
                        testConfig.payload.outputs = testCase.body.outputs
                        testConfig.body = JSON.stringify(testConfig.payload)
                        psConfigs.push({
                            inputFile: testCase.body.inputs,
                            config: testConfig,
                            testCase: testCase
                        })
                        break
                    case 'psFileConversion':
                        testConfig = psFileConversionConfig(testCase.body.inputs[0], testCase.body.outputs, null)
                        testConfig.payload.outputs = testCase.body.outputs
                        testConfig.body = JSON.stringify(testConfig.payload)
                        psConfigs.push({
                            inputFile: testCase.body.inputs,
                            config: testConfig,
                            testCase: testCase
                        })
                        break
                    case 'psActions':
                        testConfig = psActionsConfig(testCase.body.inputs[0], testCase.body.outputs, null, testCase.body.options.actions)
                        testConfig.payload.outputs = testCase.body.outputs
                        testConfig.body = JSON.stringify(testConfig.payload)
                        psConfigs.push({
                            inputFile: testCase.body.inputs,
                            config: testConfig,
                            testCase: testCase
                        })
                        break
                    case 'psSmartObjectV2':
                        testConfig = psSmartObjectV2Config(testCase.body.inputs[0], testCase.body.outputs, null, null, testCase.body.options.layers)
                        testConfig.payload.outputs = testCase.body.outputs
                        testConfig.body = JSON.stringify(testConfig.payload)
                        psConfigs.push({
                            inputFile: testCase.body.inputs,
                            config: testConfig,
                            testCase: testCase
                        })
                        break
                    case 'ssImagecutout':
                        testConfig = ssImagecutoutConfig(testCase.body.input, testCase.body.output, null, null)
                        testConfig.payload.output = testCase.body.output
                        testConfig.body = JSON.stringify(testConfig.payload)
                        psConfigs.push({
                            inputFile: testCase.body.input,
                            config: testConfig,
                            testCase: testCase
                        })
                        break
                    default:
                    }
                })
            }
        })
    }

    async function start (config) {
        dispatch({ type: 'set-codes', payload: { config: config } })

        const client = await sdk.init(null, process.env.REACT_APP_CONFIG_APP_KEY, window.adobeIMS.getAccessToken().token)
        let job = null
        switch (true) {
        case /cutout/.test(config.config.url):
            job = await client.createCutout(config.config.payload.input, config.config.payload.output)
            break
        case /photoshopActions/.test(config.config.url):
            job = await client.applyPhotoshopActions(config.config.payload.inputs[0], config.config.payload.outputs, config.config.payload.options)
            break
        case /documentManifest/.test(config.config.url):
            job = await client.getDocumentManifest(config.config.payload.inputs[0])
            break
        case /documentOperations/.test(config.config.url):
            job = await client.modifyDocument(config.config.payload.inputs[0], config.config.payload.outputs, config.config.payload.options)
            break
        case /renditionCreate/.test(config.config.url):
            job = await client.createRendition(config.config.payload.inputs[0], config.config.payload.outputs)
            break
        default:
            break
        }
        if (job && job.isDone()) {
            const resStatus = job.outputs && job.outputs.length > 0 && job.outputs[0].status
            if (resStatus === 'succeeded') {
                files = []
                const input = config.config.payload.input || (config.config.payload.inputs && config.config.payload.inputs[0])
                const output = config.config.payload.output || (config.config.payload.outputs && config.config.payload.outputs[0])
                files.push({
                    inputFile: input,
                    name: getFilename(input.href),
                    shortName: getFilenameWithoutExt(input.href),
                    path: input.href,
                    outputThumbnail: output && getThumbnail(output, 300),
                    output: output && getThumbnail(output, 0),
                    outputToDownload: output && getOutputToDownload(output, 0),
                    rendition: output,
                    config: config,
                    documentManifest: job.outputs && job.outputs[0] && job.outputs[0].document && job.outputs[0]
                })
                dispatch({
                    type: 'set-test-result',
                    payload: {
                        config: config,
                        msg: 'succeeded',
                        files: files,
                        error: null,
                        res: job
                    }
                })
            } else if (resStatus === 'failed') {
                dispatch({
                    type: 'set-test-result',
                    payload: {
                        config: config,
                        msg: `${JSON.stringify(config.config)} - ${resStatus}` || 'failed',
                        error: error,
                        res: job
                    }
                })
            }
        } else {
            smartObjectV2(config)
        }
    }

    function smartObjectV2 (config) { // not available in sdk yet
        dispatch({ type: 'set-codes', payload: { config: config } })
        // config.config.timeout = 10000
        apiCall(config.config, (id, json) => {
            if (id) {
                getStatus(id, config.config, (res, error, errResponse) => {
                    if (error) {
                        dispatch({
                            type: 'set-test-result',
                            payload: {
                                config: config,
                                msg: `${JSON.stringify(error)} - ${JSON.stringify(config.inputFile)} ${errResponse} ${res}`,
                                error: error,
                                res: res
                            }
                        })
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                        dispatch({
                            type: 'set-test-result',
                            payload: {
                                config: config,
                                msg: `${JSON.stringify(config.inputFile)} - ${errMsg}` || 'failed',
                                error: error,
                                res: res
                            }
                        })
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        files = []
                        if (res.outputs[0] && res.outputs[0]._links && res.outputs[0]._links.renditions) {
                            res.outputs[0]._links.renditions.forEach(rendition => {
                                const file = config.inputFile.href ? config.inputFile : config.inputFile[0]
                                files.push({
                                    inputFile: file,
                                    name: getFilename(file.href),
                                    shortName: getFilenameWithoutExt(file.href),
                                    path: file.href,
                                    outputThumbnail: getThumbnail(rendition, 300),
                                    output: getThumbnail(rendition, 0),
                                    outputToDownload: getOutputToDownload(rendition, 0),
                                    rendition: rendition,
                                    config: config
                                })
                            })
                            dispatch({
                                type: 'set-test-result',
                                payload: {
                                    config: config,
                                    msg: 'succeeded',
                                    files: files,
                                    error: null,
                                    res: res
                                }
                            })
                        } else {
                            files.push(res.outputs[0])
                            dispatch({
                                type: 'set-test-result',
                                payload: {
                                    config: config,
                                    msg: 'succeeded',
                                    files: files,
                                    error: null,
                                    res: res
                                }
                            })
                        }
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                // errorOccured(`3 ${JSON.stringify(config.inputFile)} - ${errMsg}`, json)
                dispatch({
                    type: 'set-test-result',
                    payload: {
                        config: config,
                        msg: `${errMsg}`,
                        error: true,
                        res: json
                    }
                })
            }
        })
    }

    function isRun (val) {
        const testCases = getLocalStorageItem('apiTestCases') || setLocalStorageItem('apiTestCases', apiTestCases)
        return testCases.filter(testCase => testCase.testCase === val && testCase.selected).length > 0
    }

    function whichTestType () {
        const testTypes = getLocalStorageItem('apiTestTypes') || setLocalStorageItem('apiTestTypes', apiTestTypes)
        return testTypes.find(testType => testType.selected === true).value
    }

    function isTotalRun () {
        const testCases = getLocalStorageItem('apiTestCases') || setLocalStorageItem('apiTestCases', apiTestCases)
        return testCases.filter(testCase => testCase.selected).length
    }

    async function run (e) {
        try {
            dispatch({ type: 'skycity-run-test', payload: {} })
            // const updateTestfilesRes = await updateTestfiles()
            // if (updateTestfilesRes) {
            await getCases()
            if (psConfigs.length > 0) {
                setTestCaseCount(psConfigs.length)
                info(`${psConfigs.length} test cases will be executed.`, {
                    timeout: 5000
                })
                files = []
                const funcArray = []

                psConfigs.forEach(config => {
                    funcArray.push(start(config))
                })
                axios.all(funcArray)
                    .then((res) => {
                        // then
                    })
                    .catch((err) => {
                        const errMsg = `ERROR: ${JSON.stringify(err)}`
                        errorOccured(errMsg)
                    })
                    .finally(() => {
                        console.log('finally...')
                    })
            } else {
                errorOccured('Something went wrong')
            }
            // }
        } catch (e) {
            error(`1 ${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function getTable () {
        const columns = [
            {
                title: 'result',
                key: 'msg',
                width: 60,
                divider: true
            },
            {
                title: 'job id',
                key: 'res',
                width: 300,
                divider: true
            },
            {
                title: 'Script',
                key: 'config',
                width: 60,
                divider: true
            },
            {
                title: 'area',
                key: 'area',
                width: 200,
                sortable: true,
                divider: true
            },
            {
                title: 'feature',
                key: 'feature',
                width: 200,
                sortable: true,
                divider: true
            },
            {
                title: 'Files (Input/Output)',
                width: 200,
                key: 'files',
                divider: true
            },
            {
                title: 'Test Case (Click to see the payload in console)',
                key: 'desc',
                width: 600
            }
        ]
        if (testResultDs && testResultDs.data && testResultDs.data.length !== 0) {
            // if (testResultDs.data.length === testCaseCount) {
            //     setInProgress(false)
            // }
            return <div className="adobe-skycity-table-container">
                <TableView
                    columns={columns}
                    rowHeight={ 100 }
                    onCellDoubleClick={ e => onCellDoubleClick(e) }
                    dataSource={testResultDs}
                    renderCell={renderCell} />
            </div>
        } else {
            return null
        }
    }

    function onCellDoubleClick (e) {
        // console.log(e.key)
        // console.log(getCodeSnippetText(1, [e.key]))
    }

    function getResInfo (e, val, res) {
        const msg = JSON.stringify(res, null, 2)
        const timeout = 5000
        let idMsg = ''
        console.log(msg)

        if (res && res.jobId) {
            idMsg = `Copied Job ID (${res.jobId}).  See console for more detail.`
            copyText(res.jobId)
            switch (val) {
            case 'succeeded':
                success(idMsg, {
                    timeout: timeout
                })
                break
            default:
                if (res) {
                    const errorTitle = (res.outputs && res.outputs[0] && res.outputs[0].errors && res.outputs[0].errors.title) || null
                    if (errorTitle) {
                        idMsg = `Copied Job ID (${res.jobId}).  ERROR: ${errorTitle}  See console for more detail.`
                    }
                }
                error(`${idMsg}`, {
                    timeout: timeout
                })
            }
        } else {
            idMsg = `Job ID Not Found. code: ${res.code} title: ${res.title} type: ${res.type} See console for more detail.`
            copyText(idMsg)
            error(`${idMsg}`, {
                timeout: timeout
            })
        }
    }

    function renderCell (column, data) {
        const val = data[column.key]
        switch (column.key) {
        case 'msg':
            switch (val) {
            case 'succeeded':
                return <div onClick={ e => getResInfo(e, val, data.res) }><StatusLight variant="celery" /></div>
            default:
                return <div onClick={ e => getResInfo(e, val, data.res) }><StatusLight variant="magenta" /></div>
            }
        case 'res':
            return <div onClick={ e => getResInfo(e, data.msg, data.res) }><Link>{((data.res && data.res.jobId) || data.jobId) || 'error'}</Link></div>
        case 'config':
            val.config.codeEndpoint = val.config.url
            val.config.codePayload = val.config.payload
            val.config.codeMethod = val.config.method
            // return <span onClick={ e => downloadScript(e, val) }>download</span>;
            return <Button variant="tool" onClick={ e => downloadScript(e, val) } icon={<Download />} aria-label="Download" />
        case 'files':
            if (data.files && data.files.length > 0) {
                const files = data.files
                let i = 0
                const outputs = files.map(file => {
                    if (file.inputFile) {
                        const isInputPsd = (getExtensionFromPath(file.inputFile.href) === 'PSD')
                        const isOutputPsd = file && file.rendition && (file.rendition.type === 'vnd.adobe.photoshop')
                        const beforeImage = (getStorage(file.inputFile) === 'adobe') ? getRendition(file.inputFile) : file.inputFile.href
                        const afterImage = file.output
                        const afterImageThumbnail = file.outputThumbnail
                        // if (file.inputFile.type !== "PSD") {
                        //     beforeImage = getRenditionCreate(file.inputFile)
                        // }
                        i++
                        return <div className="adobe-skycity-test-case-files" key={`${i}`}>
                            <ModalTrigger>
                                <div>
                                    <div className="adobe-skycity-test-case">
                                        <div>{`Case${i} (${getTestCaseInfo(file)})`}</div>
                                        <div className="adobe-skycity-test-case-files">
                                            { isInputPsd && (getStorage(file.inputFile) !== 'adobe') ? <Image
                                                className="adobe-skycity-test-case-files-image"
                                                src={imgPsd} alt=""/> : <Asset
                                                className="adobe-skycity-test-case-files-image"
                                                type="image"
                                                src={beforeImage} cache/> }
                                            { isOutputPsd && (getStorage(file.inputFile) !== 'adobe') ? <Image
                                                className="adobe-skycity-test-case-files-image"
                                                src={imgPsd} alt=""/> : <Asset
                                                className="adobe-skycity-test-case-files-image"
                                                type="image"
                                                src={afterImageThumbnail} cache/> }
                                        </div>
                                    </div>
                                </div>
                                <Dialog title={`Case ${i}`} confirmLabel="OK" mode="fullscreen">
                                    <div className="adobe-skycity-test-case-files">
                                        { getOutputFileLarge(isInputPsd, file, (file.inputFile && file.inputFile.href), ((file.inputFile && file.inputFile.type) || null), beforeImage, null, data.config.config.body) || null}
                                        { getOutputFileLarge(isOutputPsd, file, (file.outputToDownload || null), ((file.rendition && file.rendition.type) || null), afterImage, data.res, data.config.config.body)}
                                    </div>
                                </Dialog>
                            </ModalTrigger>
                        </div>
                    } else {
                        return null
                    }
                })
                return outputs
            }
            break
        case 'desc':
            return <div className="adobe-skycity-test-case-json" onClick={ e => console.log(JSON.stringify(data.config.testCase.body, null, 2))}>
                <code>{data.config.testCase[column.key]}</code>
            </div>
        default:
            return <div>{data.config.testCase[column.key]}</div>
        }
    }

    function getTestCaseInfo (file) {
        return file && file.rendition ? file.rendition.type : 'unknown'
    }

    function getOutputFileLarge (isPsd, file, href, type, img, res, payload) {
        const myInput = payload ? JSON.parse(payload) : null
        if (img) {
            const comp = <div className="adobe-skycity-test-case-large-preview">
                {res ? 'Output:' : 'Input:'}
                <Well>
                    <p onClick={ e => downloadFile(href, file.name)}>
                        { isPsd && (getStorage(file.inputFile) !== 'adobe') ? <Image
                            className="adobe-skycity-test-case-files-image-large"
                            src={imgPsd} alt=""/> : <Asset
                            type="image"
                            src={img} cache/> }
                    </p>
                    <div>Name: {file.shortName}</div>
                    <div>Type: {type || getExtensionFromPath(file.path)}</div>
                    <div>Path: <a href={`${img}`} target="_blank" rel="noopener noreferrer">{getImageName(isPsd, img)}</a></div>
                    { res ? <div>Response:
                        <SyntaxHighlighter lineNumberStyle={lineNumberStyle} showLineNumbers={showLineNumbers} language={language} style={style}>
                            {JSON.stringify(res, null, 2)}
                        </SyntaxHighlighter>
                    </div> : <div>Payload:
                        <SyntaxHighlighter lineNumberStyle={lineNumberStyle} showLineNumbers={showLineNumbers} language={language} style={style}>
                            {JSON.stringify(myInput, null, 2)}
                        </SyntaxHighlighter>
                    </div>}
                </Well>
            </div>
            return comp
        } else {
            const comp = <div className="adobe-skycity-test-case-large-preview">
                {res ? 'Output:' : 'Input:'}
                <Well>
                    { res ? <div>Response:
                        <SyntaxHighlighter lineNumberStyle={lineNumberStyle} showLineNumbers={showLineNumbers} language={language} style={style}>
                            {JSON.stringify(res, null, 2)}
                        </SyntaxHighlighter>
                    </div> : <div>Payload:
                        <SyntaxHighlighter lineNumberStyle={lineNumberStyle} showLineNumbers={showLineNumbers} language={language} style={style}>
                            {JSON.stringify(myInput, null, 2)}
                        </SyntaxHighlighter>
                    </div>}
                </Well>
            </div>
            return comp
        }
    }

    function getImageName (isPsd, img) {
        // return isPsd ? img : 'File'
        return 'File'
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function changeTestOptionsSelection (e, curSelection) {
        const testCases = getLocalStorageItem('apiTestCases') || setLocalStorageItem('apiTestCases', apiTestCases)
        const newSelections = testCases.map(testCase => {
            if (testCase.testCase === curSelection.testCase) {
                testCase.selected = e
            }
            return testCase
        })
        setLocalStorageItem('apiTestCases', newSelections)
    }

    function changeTestTypesOption (e) {
        const testTypes = getLocalStorageItem('apiTestTypes') || setLocalStorageItem('apiTestTypes', apiTestTypes)
        const newSelections = testTypes.map(testType => {
            testType.selected = false
            if (testType.value === e) {
                testType.selected = true
            }
            return testType
        })
        setLocalStorageItem('apiTestTypes', newSelections)
        // dispatch({ type: 'set-testType', payload: { apiTestTypes: newSelections } })
    }

    function getTestOptions () {
        const testCases = getLocalStorageItem('apiTestCases') || setLocalStorageItem('apiTestCases', apiTestCases)
        const options = testCases.map(st => {
            return <Checkbox value={st.selected}
                defaultChecked={st.selected}
                key={st.testCase}
                id={st.testCase}
                disabled={st.disabled}
                onChange={ e => changeTestOptionsSelection(e, st)}>{st.label}</Checkbox>
        })
        return <div id="stArea">{options}</div>
    }

    function getTestTypes () {
        const testTypes = getLocalStorageItem('apiTestTypes') || setLocalStorageItem('apiTestTypes', apiTestTypes)
        const options = testTypes.map(tType => {
            return <Radio label={tType.label} key={tType.value} value={tType.value} disabled={tType.disabled}/>
        })
        return <div id="ttArea"><RadioGroup onChange={ e => changeTestTypesOption(e) }
            defaultSelectedValue={whichTestType()} name="radio-group">{options}</RadioGroup></div>
    }

    // function getTestFiles () {
    //     const options = apiTestFiles.map(st => {
    //         return <Checkbox value={st.selected}
    //             defaultChecked={st.selected}
    //             key={st.testCase}
    //             id={st.testCase}
    //             disabled={st.disabled}
    //             onChange={ e => changeTestFilesSelection(e, st)}>{st.label}</Checkbox>
    //     })
    //     return <div id="stArea">{options}</div>
    // }

    function changeStorageTypesSelection (e, curSelection) {
        const sTypes = getLocalStorageItem('storageTypes') || setLocalStorageItem('storageTypes', storageTypes)
        const newSelections = sTypes.map(type => {
            if (type.value === curSelection.value) {
                type.selected = e
            }
            return type
        })
        setLocalStorageItem('storageTypes', newSelections)
        // dispatch({ type: 'set-storageTypes', payload: { storageTypes: newSelections } })
    }

    function getTestFilesOptions () {
        return <Well variant="warning">
            <div><span className="adobe-skycity-red-star">* </span>If you like to run test using CC assets, you need to copy test files into your CC account:</div>
            <ol>
                <li>Delete existing <a href={`${process.env.REACT_APP_CONFIG_URL_CCASSET_FILES}`} target="_blank" rel="noreferrer">{__('cc_smoketest_testfile_dir')}</a> from your CC storage if you already have one</li>
                <li>Go to <a href={`${process.env.REACT_APP_CONFIG_URL_CC_SHARED_TESTFILE}`} target="_blank" rel="noreferrer">HERE</a> and click <span className="adobe-skycity-warning">Copy to Your work</span> to copy the latest test files to your CC storage</li>
            </ol>
        </Well>
    }

    function getStorageTypesOptions () {
        const sTypes = getLocalStorageItem('storageTypes') || setLocalStorageItem('storageTypes', storageTypes)
        const options = sTypes.map(st => {
            return <Checkbox value={st.selected}
                defaultChecked={st.selected}
                key={st.value}
                id={st.value}
                onChange={ e => changeStorageTypesSelection(e, st)}>{st.label}{st.label === 'cc' ? <span className="adobe-skycity-red-star"> *</span> : null}</Checkbox>
        })
        return <div id="stStorage">{options}</div>
    }

    function isRunDisabled () {
        const sTypes = getLocalStorageItem('storageTypes') || setLocalStorageItem('storageTypes', storageTypes)
        return isTotalRun() === 0 || getStorageTypes(sTypes).length === 0
    }

    function ToolTestApp () {
        try {
            return <ToolTestContextProvider>
                <Heading variant="pageTitle">{__('skycity_comp_title_test_table')}</Heading>
                <div className="adobe-skycity-output-options-file-format">
                    <FieldLabel label="TEST TYPE: " labelFor="ttArea" />
                    {getTestTypes()}
                    <FieldLabel label="TEST AREA: " labelFor="stArea" />
                    {getTestOptions()}
                    <FieldLabel label="STORAGE TYPE: " labelFor="stStorage" />
                    {getStorageTypesOptions()}
                    <FieldLabel label="TEST FILE: " labelFor="stTestFiles" />
                    {/* {getTestFiles()} <div className={isUpdateTestfiles() ? 'adobe-skycity-warning' : 'adobe-skycity-hide'}>WARNING!!! Selecting this option will archive your existing files/_skycity_smoketest and copy the latest contetns into files/_skycity_smoketest in your CC</div> */}
                    {getTestFilesOptions()}
                </div>
                <br />
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
                <br /><br />
                <Progress
                    label={`Test Progress ${testResultDs && testResultDs.data ? testResultDs.data.length : 0}/${testCaseCount}`}
                    showPercent
                    labelPosition="top"
                    min={0}
                    max={testCaseCount || 100}
                    value={testResultDs && testResultDs.data ? testResultDs.data.length : 0}
                    aria-valuetext="Page 5 of 17"
                    title="Page 5 of 17"
                />
                <br /><br />
                { getTable() }
            </ToolTestContextProvider>
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }
    return <ToolTestApp />
}
