import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import OutputOptions from '../Shared/OutputOptions'

import axios from 'axios'

import { apiCall, getStatus } from '../../lib/cisApi.js'
import {
    getRenditionCreateWithUtilsStorage,
    getFileType,
    getOutputFileName
} from '../../lib/skycity.js'
import { getConfig } from '../../asset/code/psSmartObject.js'
export default function Operation (files) {
    const {
        inputFiles,
        inputSecondFiles,
        selectedLayers,
        outputOptions,
        iccProfileOption,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    async function start (curFile, curSecondFile) {
        const config = await getConfig(curFile, outputOptions, iccProfileOption, curSecondFile, selectedLayers)
        dispatch({ type: 'set-codes', payload: { config: config } })

        apiCall(config, (id, json) => {
            if (id) {
                getStatus(id, config, (res, error, errResponse) => {
                    if (error) {
                        errorOccured(`${JSON.stringify(error)} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                        errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        for (const rendition of config.payload.outputs) {
                            const outputFile = {
                                href: rendition.getHref,
                                storage: 'external',
                                type: rendition.type
                            }
                            getRenditionCreateWithUtilsStorage(outputFile, 300, 0, (err, renditionSmall, renditionLarge) => {
                                if (err) {
                                    console.log(`ERROR: ${JSON.stringify(err)}`)
                                    error(`ERROR: ${JSON.stringify(err)}`, {
                                        timeout: 0
                                    })
                                } else {
                                    files.push({
                                        inputFile: curFile,
                                        name: getOutputFileName(curFile, rendition),
                                        shortName: getFileType(rendition.type),
                                        path: rendition.href,
                                        outputThumbnail: renditionSmall,
                                        output: renditionLarge,
                                        outputToDownload: rendition.getHref
                                    })

                                    dispatch({
                                        type: 'skycity-success',
                                        payload: {
                                            files: files,
                                            loading: false,
                                            wait: false,
                                            response: res
                                        }
                                    })
                                }
                            })
                        }
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run-smartObject', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                inputSecondFiles.forEach(curSecondFile => {
                    funcArray.push(start(curFile, curSecondFile))
                })
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0 &&
            inputSecondFiles && inputSecondFiles.length > 0 &&
            outputOptions && outputOptions.find(output => output.selected === true) &&
            selectedLayers && selectedLayers.length > 0 && selectedLayers.filter(layer => layer.type === 'smartObject').length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <OutputOptions imageTypes={['image/jpeg', 'image/png', 'image/vnd.adobe.photoshop', 'image/tiff']}/>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
