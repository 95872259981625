import { __ } from 'i18n-for-browser'
import '../config/i18n'
import { error } from '@react/react-spectrum/Toast'
import axios from 'axios'

const request = require('request')

export function ccApiCall (method, url, options, cb) {
    try {
        request(options, (error, response, body) => {
            let json
            if (error) {
                console.log(`error: ${error}`)
            }
            if (body) {
                json = JSON.parse(body)
                if (response && response.statusCode >= 400) {
                    cb(null, json)
                } else {
                    cb(json, response)
                }
            } else {
                cb(null, `${response}, JSON.stringify(options): ${JSON.stringify(options)}`)
            }
        })
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
        cb(null, e)
    }
};

export async function ssApiCall (options) {
    try {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            xhr.open('POST', options.url)
            xhr.onload = () => {
                resolve(xhr)
            }
            xhr.onerror = () => {
                resolve(xhr)
            }
            xhr.ontimeout = e => {
                console.log('timeout')
                reject(new Error('timeout'))
            }
            xhr.setRequestHeader('X-Api-Key', `${process.env.REACT_APP_CONFIG_APP_ML_APIKEY}`)
            xhr.setRequestHeader('Authorization', options.headers.Authorization)
            xhr.timeout = options.timeout
            xhr.send(options.body.formData)
        })
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
};

export async function ssApiSyncCall (options) {
    const response = await fetch(options.url, {
        headers: {
            'X-Api-Key': `${process.env.REACT_APP_CONFIG_APP_ML_APIKEY}`,
            Authorization: options.headers.Authorization,
            Prefer: 'respond-sync, wait=60'
        },
        body: options.body.formData,
        method: 'post'
    })

    if (!response.ok) {
        console.log(response)
        throw new Error('Failed to call Sync service')
    }

    // Temp solution for parsing CPF output
    const blob = await response.blob()
    const newResponse = new Response(blob, {
        headers: [
            [
                'Content-Type',
                response.headers.get('Content-Type').replace('multipart/mixed', 'multipart/form-data')
            ]
        ]
    })
    if (response.headers.get('Content-Type').includes('application/json')) {
        const errResponse = await newResponse.json()
        console.error(errResponse)
        throw new Error(errResponse.statuses[0].invocations[0].message)
    }
    const formData = await newResponse.formData()
    return formData
};

export async function colligoSyncCall (options, outputFormat = 'png') {
    const response = await fetch(options.url, {
        headers: {
            'X-Api-Key': `${process.env.REACT_APP_CONFIG_APP_ML_APIKEY}`,
            Authorization: options.headers.Authorization,
            Accept: 'multipart/form-data'
        },
        body: options.body.formData,
        method: 'post'
    })

    if (!response.ok) {
        console.log(response)
        throw new Error('Failed to call Sync service')
    }

    // Error handling
    if (response.headers.get('Content-Type').includes('application/json')) {
        const errResponse = await response.json()
        console.error(errResponse)
        throw new Error(errResponse.statuses[0].invocations[0].message)
    }

    const buffer = await response.arrayBuffer()
    const uint8Array = new Uint8Array(buffer)

    // Convert to string to find header
    const decoder = new TextDecoder()
    const text = decoder.decode(uint8Array)

    // Find PNG data start after double CRLF
    const pngHeaderIndex = text.indexOf(`Content-Type: image/${outputFormat}`)
    const pngDataStartIndex = text.indexOf('\r\n\r\n', pngHeaderIndex) + 4

    // Extract PNG binary data
    const pngData = uint8Array.slice(pngDataStartIndex)

    const blob = new Blob([pngData], { type: `image/${outputFormat}` })
    return blob
};

export async function apiCallSensei (options, cb) {
    try {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            xhr.open('POST', options.url)
            xhr.onload = () => {
                cb(xhr)
            }
            xhr.onerror = () => {
                cb(xhr)
            }
            xhr.ontimeout = e => {
                console.log('timeout')
            }
            xhr.setRequestHeader('X-Api-Key', `${process.env.REACT_APP_CONFIG_APP_ML_APIKEY}`)
            xhr.setRequestHeader('Authorization', options.headers.Authorization)
            xhr.timeout = options.timeout
            xhr.send(options.body.formData)
        })
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
        cb(null, e)
    }
};

function pollStatus (url, options) {
    return axios.get(url, options)
        .then((response) => {
            // handle success
            return response
        })
        .catch((error) => {
        // handle error
            console.log(`pollStatus - error: ${JSON.stringify(error)}`)
        })
}

function sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export async function getSenseiStatus (statusURL, timeout, interval) {
    const options = {
        timeout: 60000,
        headers: {
            Authorization: `Bearer ${window.adobeIMS.getAccessToken().token}`,
            'X-api-key': `${process.env.REACT_APP_CONFIG_APP_ML_APIKEY}`
        }
    }
    var endTime = Date.now() + (timeout || 60000)
    interval = interval || 1000
    const validStatus = ['200', '400', '500']
    let response = await pollStatus(statusURL, options)
    let res = (response && response.data && response.data.statuses && Array.isArray(response.data.statuses) && response.data.statuses[0].invocations) ? response.data.statuses[0].invocations[0] : response.data
    while (!res || !validStatus.includes(res.status) || Date.now() > endTime) {
        console.log(`timing out in ${Math.floor((endTime - Date.now()) / 1000)} sec...`)
        await sleep(interval)
        response = await pollStatus(statusURL, options)
        res = (response && response.data && response.data.statuses && Array.isArray(response.data.statuses) && response.data.statuses[0].invocations) ? response.data.statuses[0].invocations[0] : response.data
    }
    return res
}

export async function ccApiCallGetFile (options, cb) {
    try {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            xhr.open('GET', options.url)
            xhr.onload = () => {
                cb(xhr)
            }
            xhr.onerror = () => {
                error(`${__('error_msg_title')}: in ccApiCallGetFile`, {
                    timeout: 0
                })
                cb(null)
            }
            xhr.ontimeout = e => {
                console.log(`Timeout - ${e}`)
                error(`${__('error_msg_title')}: Timeout - ${e}`, {
                    timeout: 0
                })
                cb(null, e)
            }
            xhr.setRequestHeader('X-Api-Key', `${process.env.REACT_APP_CONFIG_APP_KEY}`)
            // xhr.setRequestHeader('X-Api-Key', options.headers['X-Api-Key']);
            xhr.setRequestHeader('Authorization', options.headers.Authorization)
            xhr.setRequestHeader('Content-Type', '*/*')
            xhr.setRequestHeader('Access-Control-Allow-Origin', '*/*')
            // xhr.setRequestHeader('Strict-Transport-Security', 'max-age=10368000; includeSubDomains')
            xhr.responseType = options.responseType
            xhr.timeout = options.timeout
            xhr.send({})
        })
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
        cb(null, e)
    }
};

export async function archiveSkycityTestfiles () {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/ops?invocation_mode=sync`,
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_CONFIG_APP_KEY,
                    Authorization: `Bearer ${window.adobeIMS.getAccessToken().token}`,
                    'Content-Type': 'application/vnd.adobe.asset-operation+json',
                    body: JSON.stringify({
                        op: 'archive',
                        target: {
                            href: '/files/_skycity_smoketest/'
                        },
                        source: {
                            href: '/archive/_skycity_smoketest'
                        }
                    })
                }
            }
        )
        return {
            response: response,
            error: false
        }
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
        return {
            response: null,
            error: e
        }
    }
}
