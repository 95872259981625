import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import SelectProductCropOptions from '../Shared/SelectProductCropOptions'
import OutputOptions from '../Shared/OutputOptions'

import { apiCall, getStatus } from '../../lib/cisApi.js'
import { getOptionsOutput, getHeaders, getStorage, getFileType, getOutputFileName, getRenditionCreateWithUtilsStorage } from '../../lib/skycity.js'

import axios from 'axios'

export default function Operation (files) {
    const {
        inputFiles,
        outputOptions,
        iccProfileOption,
        productCropOptions,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    async function start (curFile) {
        /* *********************************** */
        const codePayload = {
            inputs: [
                {
                    href: curFile.path,
                    storage: getStorage(curFile)
                }
            ],
            options: {
                unit: productCropOptions.units.find(unit => unit.selected === true).value,
                width: productCropOptions.width,
                height: productCropOptions.height
            },
            outputs: await getOptionsOutput(curFile, outputOptions, iccProfileOption)
        }

        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/productCrop`,
            method: 'POST',
            timeout: 90000,
            headers: getHeaders(),
            payload: codePayload,
            body: JSON.stringify(codePayload)
        }
        /* *********************************** */

        dispatch({ type: 'set-codes', payload: { codePayload: codePayload, config: config, codeEndpoint: config.url, codeMethod: config.method } })
        apiCall(config, (id, json) => {
            if (id) {
                getStatus(id, config, (res, error, errResponse) => {
                    if (error) {
                        errorOccured(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                        errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        // ---------------------------
                        // Photoshop API
                        for (const rendition of config.payload.outputs) {
                            const outputFile = {
                                href: rendition.getHref,
                                storage: 'external',
                                type: rendition.type
                            }
                            getRenditionCreateWithUtilsStorage(outputFile, 300, 0, (err, renditionSmall, renditionLarge) => {
                                if (err) {
                                    console.log(`ERROR: ${JSON.stringify(err)}`)
                                    error(`ERROR: ${JSON.stringify(err)}`, {
                                        timeout: 0
                                    })
                                } else {
                                    files.push({
                                        inputFile: curFile,
                                        name: getOutputFileName(curFile, rendition),
                                        shortName: getFileType(rendition.type),
                                        path: rendition.href,
                                        outputThumbnail: renditionSmall,
                                        output: renditionLarge,
                                        outputToDownload: rendition.getHref
                                    })

                                    dispatch({
                                        type: 'skycity-success',
                                        payload: {
                                            files: files,
                                            selectedInputFile: files[0],
                                            showLayerInfoPane: true,
                                            loading: false,
                                            wait: false,
                                            response: res
                                        }
                                    })
                                }
                            })
                        }
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(inputFile => {
                funcArray.push(start(inputFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    // const errMsg = `ERROR: ${(Object.keys(err).length === 0 && err.constructor === Object) ? JSON.stringify(err) : err}`;
                    console.log(`err: ${err}`)
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <SelectProductCropOptions />
            <OutputOptions imageTypes={['image/jpeg', 'image/png', 'image/vnd.adobe.photoshop', 'image/tiff']} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
