import { useContext } from 'react'
import { SkycityContext } from '../lib/SkycityProvider'

import { getFilename } from '../lib/skycity.js'

import smartObjectReplacement from '../asset/testfiles/testfile_smartObject.zip'
import psdFile from '../asset/testfiles/testfile_ps_lr.zip'
import cropFile from '../asset/testfiles/testfile_ps_crop.zip'
import lrFile from '../asset/testfiles/testfile_lr.zip'
import lrAutoStraightenTestFile from '../asset/testfiles/testfile_lrAutoStraighten.zip'
import fileFormats from '../asset/testfiles/testfile_fileFormat.zip'
import xmpFile from '../asset/testfiles/testfile_xmp.zip'
import autoTagFile from '../asset/testfiles/testfile_autotag.zip'
import pieOnSenseiFile from '../asset/testfiles/testfile_pieonsensei.zip'
import reflowTestFile from '../asset/testfiles/testfile_reflow.zip'
import ssgenerationTestFile from '../asset/testfiles/testfile_ssgeneration.zip'
import ssbodyparserTestFile from '../asset/testfiles/testfile_ssbodyparser.zip'
import ssacrmaskingTestFile from '../asset/testfiles/testfile_ssacrmasking.zip'
import sscmganholefillingTestFile from '../asset/testfiles/testfile_sscmganholefilling.zip'
import sstrimapmattingTestFile from '../asset/testfiles/testfile_sstrimapmatting.zip'
import ssshadowdetectionTestFile from '../asset/testfiles/testfile_shadowDetection.zip'
import ssshadowremovalTestFile from '../asset/testfiles/testfile_shadowRemoval.zip'
import ssshadowgeneratorTestFile from '../asset/testfiles/testfile_shadowGenerator.zip'
import ssuniversalrefinementTestFile from '../asset/testfiles/testfile_ssuniversalrefinement.zip'
import ssmaskrefinementTestFile from '../asset/testfiles/testfile_ssmaskrefinement.zip'
import ssobjectselectionTestFile from '../asset/testfiles/testfiles_objectSelection.zip'
import ssentitySegmentationTestFile from '../asset/testfiles/testfiles_entitySegmentation.zip'
import sssegmentAnythingTestFile from '../asset/testfiles/testfile_segmentAnything.zip'
import ssselectallTestFile from '../asset/testfiles/testfile_selectAll.zip'
import ssmetacafTestFile from '../asset/testfiles/testfile_metacaf.zip'
import ssllavaTestFile from '../asset/testfiles/testfile_llava.zip'
import ssparametricTestFile from '../asset/testfiles/testfile_parametricHarmonization.zip'
import sslayerSceneTestFile from '../asset/testfiles/testfile_layerSceneCompletion.zip'
import plTestFile from '../asset/testfiles/testfile_pl.zip'
import ssstuffsegmentationTestFile from '../asset/testfiles/testfile_ssstuffsegmentation.zip'
import ssimagecutoutv5TestFile from '../asset/testfiles/testfile_ssv5.zip'

const zipFileName = 'testfile.zip'

const zipFiles = {
    pssmartobject: {
        href: smartObjectReplacement,
        name: zipFileName
    },
    psdocumentmanifest: {
        href: psdFile,
        name: zipFileName
    },
    psinsertlayer: {
        href: psdFile,
        name: zipFileName
    },
    psfileconversion: {
        href: fileFormats,
        name: zipFileName
    },
    pscrop: {
        href: cropFile,
        name: zipFileName
    },
    lrapplyedit: {
        href: lrFile,
        name: zipFileName
    },
    lrapplypreset: {
        href: xmpFile,
        name: zipFileName
    },
    lrautotone: {
        href: lrFile,
        name: zipFileName
    },
    lrautostraighten: {
        href: lrAutoStraightenTestFile,
        name: zipFileName
    },
    sspieonsensei: {
        href: pieOnSenseiFile,
        name: zipFileName
    },
    ssgeneration: {
        href: ssgenerationTestFile,
        name: zipFileName
    },
    ssbodyparser: {
        href: ssbodyparserTestFile,
        name: zipFileName
    },
    ssacrmasking: {
        href: ssacrmaskingTestFile,
        name: zipFileName
    },
    sscmganholefilling: {
        href: sscmganholefillingTestFile,
        name: zipFileName
    },
    sstrimapmatting: {
        href: sstrimapmattingTestFile,
        name: zipFileName
    },
    ssshadowdetection: {
        href: ssshadowdetectionTestFile,
        name: zipFileName
    },
    ssshadowremoval: {
        href: ssshadowremovalTestFile,
        name: zipFileName
    },
    ssshadowgenerator: {
        href: ssshadowgeneratorTestFile,
        name: zipFileName
    },
    ssuniversalrefinement: {
        href: ssuniversalrefinementTestFile,
        name: zipFileName
    },
    ssmaskrefinement: {
        href: ssmaskrefinementTestFile,
        name: zipFileName
    },
    ssobjectselection: {
        href: ssobjectselectionTestFile,
        name: zipFileName
    },
    ssentitysegmentation: {
        href: ssentitySegmentationTestFile,
        name: zipFileName
    },
    sssegmentanything: {
        href: sssegmentAnythingTestFile,
        name: zipFileName
    },
    ssselectall: {
        href: ssselectallTestFile,
        name: zipFileName
    },
    ssmetacaf: {
        href: ssmetacafTestFile,
        name: zipFileName
    },
    ssllava: {
        href: ssllavaTestFile,
        name: zipFileName
    },
    ssparametricharmonization: {
        href: ssparametricTestFile,
        name: zipFileName
    },
    sslayerscene: {
        href: sslayerSceneTestFile,
        name: zipFileName
    },
    ssautotag: {
        href: autoTagFile,
        name: zipFileName
    },
    psreflowweb: {
        href: reflowTestFile,
        name: zipFileName
    },
    psreflowcep: {
        href: reflowTestFile,
        name: zipFileName
    },
    pocloc: {
        href: plTestFile,
        name: zipFileName
    },
    test: {
        href: lrFile,
        name: getFilename(lrFile)
    },
    ssstuffsegmentation: {
        href: ssstuffsegmentationTestFile,
        name: zipFileName
    },
    ssimagecutoutv5: {
        href: ssimagecutoutv5TestFile,
        name: zipFileName
    }
}

export default function useDownload () {
    const { page } = useContext(SkycityContext)
    const testFile = zipFiles[page] || {}
    return {
        testFile
    }
}
