import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'
import Heading from '@react/react-spectrum/Heading'
import Checkbox from '@react/react-spectrum/Checkbox'
import Well from '@react/react-spectrum/Well'
import LayerOptions from '../Shared/LayerOptions'
import OutputOptions from '../Shared/OutputOptions'
import SelectInput from '../Shared/SelectInput'
import { apiCall, getStatus } from '../../lib/cisApi.js'
import {
    getOptionsLayers,
    getImageLayersToInsert,
    getRenditionCreateWithUtilsStorage,
    getFileType,
    getOutputFileName
} from '../../lib/skycity.js'
import axios from 'axios'
import { getConfig } from '../../asset/code/psInsertLayer.js'

export default function Operation (files) {
    const {
        inputFiles,
        outputOptions,
        iccProfileOption,
        errorOptions,
        layerOptions,
        inputSecondFiles,
        inputSecondFile,
        insertLayerAsSmartObject,
        dispatch
    } = useContext(SkycityContext)

    async function start (curFile) {
        const adLayers = getOptionsLayers(curFile, layerOptions)
        const imageLayers = getImageLayersToInsert(inputSecondFiles, insertLayerAsSmartObject)
        const optionsLayers = adLayers.concat(imageLayers)
        const config = await getConfig(curFile, outputOptions, iccProfileOption, optionsLayers)

        dispatch({ type: 'set-codes', payload: { config } })
        apiCall(config, (id, json) => {
            if (id) {
                getStatus(id, config, (res, error, errResponse) => {
                    if (error) {
                        errorOccured(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                        errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        for (const rendition of config.payload.outputs) {
                            const outputFile = {
                                href: rendition.getHref,
                                storage: 'external',
                                type: rendition.type
                            }
                            getRenditionCreateWithUtilsStorage(outputFile, 300, 0, (err, renditionSmall, renditionLarge) => {
                                if (err) {
                                    console.log(`ERROR: ${JSON.stringify(err)}`)
                                    error(`ERROR: ${JSON.stringify(err)}`, {
                                        timeout: 0
                                    })
                                } else {
                                    files.push({
                                        inputFile: curFile,
                                        name: getOutputFileName(curFile, rendition),
                                        shortName: getFileType(rendition.type),
                                        path: rendition.href,
                                        outputThumbnail: renditionSmall,
                                        output: renditionLarge,
                                        outputToDownload: rendition.getHref
                                    })

                                    dispatch({
                                        type: 'psfileconversion-success',
                                        payload: {
                                            files: files,
                                            selectedInputFile: files[0],
                                            showLayerInfoPane: true,
                                            loading: false,
                                            wait: false,
                                            response: res
                                        }
                                    })
                                }
                            })
                        }
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(inputFile => {
                funcArray.push(start(inputFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0 &&
            outputOptions && outputOptions.find(output => output.selected === true) &&
            ((layerOptions && layerOptions.find(layer => layer.selected === true)) || (inputSecondFiles && inputSecondFiles.length > 0))) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function setLayerStyle (e) {
        dispatch({ type: 'set-insertLayerType', payload: { smartObject: e } })
    }

    try {
        return <div>
            <LayerOptions />
            <br />
            <Well>
                <Heading variant="pageTitle">{__('skycity_comp_title_layer_options_image_layer')}</Heading>
                <br />
                <Checkbox label="Insert as an embeded SmartObject Layer" defaultChecked={insertLayerAsSmartObject} onChange={ e => setLayerStyle(e) }></Checkbox>
                <br /><br />
                <SelectInput
                    storage={['as', 's3']}
                    actionType="set-input-second"
                    title=""
                    imageTypes=".cr2,.ai,.pdf,.svg,.psd,.psdc,image/png,image/tiff,image/jpeg,image/vnd.adobe.photoshop,image/*,application/illustrator,application/pdf,image/svg+xml,document/vnd.adobe.cpsd+dcx"
                    multiple={true}
                    files={inputSecondFiles}
                    file={inputSecondFile} />
            </Well>
            <br />
            <OutputOptions imageTypes={['image/jpeg', 'image/png', 'image/vnd.adobe.photoshop', 'image/tiff']}/>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
