/* eslint-disable no-undef, no-restricted-globals, no-unused-expressions, no-sequences, no-use-before-define, new-parens */
import React, { useContext } from 'react'
import { SkycityContext } from '../../lib/SkycityProvider'
import Select from '@react/react-spectrum/Select'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import Textfield from '@react/react-spectrum/Textfield'
import Well from '@react/react-spectrum/Well'
import Heading from '@react/react-spectrum/Heading'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

export default function SelectProductCropOptions () {
    const {
        dispatch,
        productCropOptions
    } = useContext(SkycityContext)

    function changeActionSelection (e, option) {
        const selectedProductCropOptions = productCropOptions
        switch (option) {
        case 'unit': {
            const newUnitOptions = selectedProductCropOptions.units.map(unit => {
                if (unit.value === e) {
                    unit.selected = true
                } else {
                    unit.selected = false
                }
                return unit
            })
            selectedProductCropOptions.units = newUnitOptions
            break
        }
        case 'width':
            selectedProductCropOptions.width = parseInt(e.target.value)
            break
        case 'height':
            selectedProductCropOptions.height = parseInt(e.target.value)
            break
        default:
            break
        }
        dispatch({ type: 'set-productCropOptions', payload: { productCropOptions: selectedProductCropOptions } })
    }

    return <div className="adobe-skycity-output-options">
        <Well>
            <Heading variant="pageTitle">{__('psproductcrop_page_title')}</Heading>
            <div className="adobe-skycity-options">
                <div className="adobe-skycity-option">
                    <FieldLabel className="adobe-skycity-options-field" label="Select Unit" position="left">
                        <Select
                            options={productCropOptions.units}
                            onChange={e => changeActionSelection(e, 'unit')}>
                        </Select>
                    </FieldLabel>
                    <FieldLabel className="adobe-skycity-options-field" label="Width" position="left">
                        <Textfield
                            defaultValue={productCropOptions.width}
                            onBlur={e => changeActionSelection(e, 'width')}>
                        </Textfield>
                    </FieldLabel>
                    <FieldLabel className="adobe-skycity-options-field" label="Height" position="left">
                        <Textfield
                            defaultValue={productCropOptions.height}
                            onBlur={e => changeActionSelection(e, 'height')}>
                        </Textfield>
                    </FieldLabel>
                </div>
            </div>
        </Well>
    </div>
}
