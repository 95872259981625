import React from 'react'
import { __ } from 'i18n-for-browser'
import '../config/i18n'
import AWS from 'aws-sdk'

import { apiCall, getStatus } from './cisApi.js'
import { ccApiCall, ccApiCallGetFile } from './ccApi.js'
import { asApiCall } from './asApi.js'
import filesize from 'filesize'
import axios from 'axios'

import { error, success } from '@react/react-spectrum/Toast'
import Folder from '@react/react-spectrum/Icon/Folder'
import Image from '@react/react-spectrum/Icon/Image'
import Document from '@react/react-spectrum/Icon/Document'
import Text from '@react/react-spectrum/Icon/Text'
import {
    generatePresignedUrlsFromUtils
} from './utilsServiceApi'

const uuid = require('uuid/v4')
const path = require('path')
let uploadTempAssetNum = 6

export function goToUrl (url) {
    window.location.href = url
}

export function goToUrlTarget (url, target) {
    window.open(url, target)
}

export function getFilename (longName) {
    const basename = path.basename(longName)
    let shortName = basename
    if (basename.match(/(.+)\?/)) {
        shortName = RegExp.$1
    }
    return shortName
}

export function getFilenameWithoutExt (longName) {
    var fileName = longName.substring(longName.lastIndexOf('/') + 1)
    return fileName.split('.').slice(0, -1).join('.')
}

export function getExtensionFromPath (longName) {
    var fileName = longName.substring(longName.lastIndexOf('/') + 1)
    return fileName.substr(fileName.lastIndexOf('.') + 1).toUpperCase()
}

export function getExtensionFromPathWithParameters (url) {
    var filePath = url.substring(0, url.indexOf('?'))
    return getExtensionFromPath(filePath)
}

export function getRendition (file, size, type) {
    if (file.base64) {
        return file.base64
    }
    if (!size) {
        size = 0
    }
    if (file.s3_id) {
        if (file.content_type === 'image/vnd.adobe.photoshop') {
            return null
        } else {
            return file.url
        }
    }
    if (file.stock_id) {
        return file.url
    }
    if (type) {
        switch (type) {
        case 'path':
            return `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(file.path)}/:rendition;size=${size}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        case 'secondInput':
            return `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(file.path)}/:rendition;size=${size}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        default:
        }
    }
    if (!file.url) {
        file.url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(file.href)}`
    }
    const rendition = `${getEncodedURI(file.url || file.href)}/:rendition;size=${size}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
    return rendition
}

export function getThumbnail (rendition, size) {
    if (!size) {
        size = 0
    }
    if (rendition.href.match(/^http/)) {
        return rendition.href
    }
    return `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(rendition.href)}/:rendition;size=${size}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
}

export function getOutputToDownload (rendition) {
    return `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(rendition.href)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
}

export function getHeaders () {
    return {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // 'Strict-Transport-Security': 'max-age=10368000; includeSubDomains',
        Authorization: `Bearer ${window.adobeIMS.getAccessToken().token}`,
        'X-api-key': `${process.env.REACT_APP_CONFIG_APP_KEY}`
    }
}

export function getHealthCheckHeaders () {
    return {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // 'Strict-Transport-Security': 'max-age=10368000; includeSubDomains',
        'X-api-key': `${process.env.REACT_APP_CONFIG_APP_KEY}`
    }
}

export function getHealthCheckHeadersNonCis () {
    return {
        'Content-Type': 'text/html',
        'X-api-key': `${process.env.REACT_APP_CONFIG_APP_KEY}`,
        // 'Strict-Transport-Security': 'max-age=10368000; includeSubDomains',
        Authorization: `Bearer ${window.adobeIMS.getAccessToken().token}`
    }
}

export function getHealthCheckCurlOptions () {
    return `--header 'Authorization: Bearer ${window.adobeIMS.getAccessToken().token}' --header 'x-api-key: ${process.env.REACT_APP_CONFIG_APP_KEY}'`
}

export function getHelloApiHeaders () {
    return {
        'X-api-key': `${process.env.REACT_APP_CONFIG_APP_KEY}`,
        Authorization: `Bearer ${window.adobeIMS.getAccessToken().token}`
    }
}

export function getImsOrgHeaders () {
    return {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        client_id: `${process.env.REACT_APP_CONFIG_APP_KEY}`,
        Authorization: `Bearer ${window.adobeIMS.getAccessToken().token}`,
        Accept: '*/*',
        'X-api-key': `${process.env.REACT_APP_CONFIG_APP_KEY}`
    }
}

export function getRawHeaders () {
    return {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${window.adobeIMS.getAccessToken().token}`,
        'X-api-key': `${process.env.REACT_APP_CONFIG_APP_KEY}`,
        Accept: 'application/vnd.adobe.s3presignedurl+json'
    }
}

export function getSenseiHeaders () {
    return {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${window.adobeIMS.getAccessToken().token}`,
        'X-api-key': `${process.env.REACT_APP_CONFIG_APP_ML_APIKEY}`
    }
}

export function getColligoHeaders () {
    return {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${window.adobeIMS.getAccessToken().token}`,
        'X-api-key': `${process.env.REACT_APP_CONFIG_APP_ML_APIKEY}`
    }
}

export function getAsHeaders () {
    return {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-api-key': `${process.env.REACT_APP_CONFIG_APP_KEY}`,
        'X-Product': `${process.env.REACT_APP_CONFIG_APP_TITLE} ${process.env.REACT_APP_CONFIG_APP_VERSION}`,
        'X-Product-Location': `${window.location.hostname}`
    }
}

export const getCcFiles = (path, cb) => {
    let url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/files`
    if (path) {
        url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${path}`
    }
    const options = {
        url: url,
        method: 'GET',
        headers: getHeaders(),
        timeout: 10000,
        body: JSON.stringify({})
    }
    ccApiCall('get', url, options, (ccFiles, res) => {
        cb(ccFiles, res)
    })
}

export const getCloudContentFiles = (path, cb) => {
    let url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/cloud-content`
    if (path) {
        url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${path}`
    }
    const options = {
        url: url,
        method: 'GET',
        headers: getHeaders(),
        timeout: 10000,
        body: JSON.stringify({})
    }
    ccApiCall('get', url, options, (cloudContent, res) => {
        cb(cloudContent, res)
    })
}

export const getCcMetadata = (path, cb) => {
    const url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${path}/:metadata`
    const options = {
        url: url,
        method: 'GET',
        headers: getHeaders(),
        timeout: 10000,
        body: JSON.stringify({})
    }
    ccApiCall('get', url, options, (metadata, res) => {
        cb(metadata, res)
    })
}

export const getRaw = (file, cb) => {
    const url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${file.path}/:raw`
    const options = {
        url: url,
        method: 'GET',
        headers: getRawHeaders(),
        timeout: 10000,
        body: JSON.stringify({})
    }
    ccApiCall('get', url, options, (ccFiles, res) => {
        cb(ccFiles, res)
    })
}

export const getFile = (file, cb) => {
    const url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${file.path}`
    const options = {
        url: url,
        method: 'GET',
        headers: getHeaders(),
        timeout: 10000,
        responseType: 'arraybuffer',
        body: JSON.stringify({})
    }
    ccApiCallGetFile(options, (response) => {
        if (response) {
            const arr = new Uint8Array(response.response)
            let i = arr.length
            const binaryString = new Array(i)
            while (i--) {
                binaryString[i] = String.fromCharCode(arr[i])
            }
            const raw = binaryString.join('')
            const b64 = btoa(raw)
            cb(b64)
        } else {
            cb(false)
        }
    })
}

export const getStockFiles = (params, locale, keywords, cb) => {
    // let url = `${process.env.REACT_APP_CONFIG_ENDPOINT_STOCK}/${params}?locale=${locale}%26search_parameters%5Bwords%5D=${keywords}`;
    const url = `${process.env.REACT_APP_CONFIG_ENDPOINT_STOCK}/${params}?locale=${locale}&search_parameters[words]=${keywords}`
    const options = {
        url: url,
        method: 'GET',
        headers: getAsHeaders(),
        timeout: 10000,
        body: JSON.stringify({})
    }
    asApiCall('get', url, options, (files, res) => {
        cb(files, res)
    })
}

export function getFileSize (num) {
    if (num) {
        return filesize(num, { round: 0 })
    } else {
        return ''
    }
}

export function getFileType (type) {
    switch (type) {
    case 'document/vnd.adobe.cpsd+dcx':
        return 'psdc'
    case 'image/jpeg':
        return 'jpeg'
    case 'image/tiff':
        return 'tiff'
    case 'image/png':
        return 'png'
    case 'image/vnd.adobe.photoshop':
        return 'psd'
    case 'vnd.adobe.photoshop':
        return 'psd'
    case 'application/illustrator':
        return 'ai'
    case 'application/pdf':
        return 'pdf'
    case 'application/octet-stream':
        return 'atn'
    case 'application/json':
        return 'json'
    case 'image/svg+xml':
        return 'svg'
    case 'application/vnd.adobe.directory+json':
        return 'Folder'
    case 'application/x-font-otf':
        return 'otf'
    case 'application/x-font-ttf':
        return 'ttf'
    case 'application/x-font-ttc':
        return 'ttc'
    case 'application/vnd.iccprofile':
        return 'icc'
    default:
        return ''
    }
}

export function getContentType (ext) {
    switch (ext.toLowerCase()) {
    case 'jpeg':
    case 'jpg':
        return 'image/jpeg'
    case 'tiff':
        return 'image/tiff'
    case 'png':
        return 'image/png'
    case 'pdf':
        return 'application/pdf'
    case 'atn':
        return 'application/octet-stream'
    case 'json':
        return 'application/json'
    case 'ai':
        return 'application/illustrator'
    case 'svg':
        return 'image/svg+xml'
    case 'psd':
        return 'image/vnd.adobe.photoshop'
    case 'psdc':
        return 'document/vnd.adobe.cpsd+dcx'
    case 'xmp':
    case 'icc':
        return 'text/plain'
    case 'csv':
        return 'text/csv'
    case '/':
        return 'Folder'
    case 'webp':
        return 'image/webp'
    default:
        return ''
    }
}

export function getDocumentManifest (curFile, cb) {
    try {
        const codePayload = {
            inputs: [
                {
                    href: curFile.path,
                    storage: curFile.storage
                }
            ],
            options: {
                thumbnails: {
                    type: 'image/png'
                }
            }
        }
        const config = {
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/documentManifest`,
            method: 'POST',
            headers: getHeaders(),
            timeout: 90000,
            body: JSON.stringify(codePayload)
        }
        apiCall(config, (id, json) => {
            if (id) {
                getStatus(id, config, (res, error, errResponse) => {
                    if (error) {
                        throw error
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                        throw new Error(errMsg)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        curFile.documentManifest = res
                        cb([curFile])
                    } else if (res && res.status === 'succeeded') {
                        curFile.documentManifest.outputs[0].layers = res.layers
                        cb([curFile])
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                throw new Error(`${curFile} - ${errMsg}, ${json}`)
            }
        })
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}

export function getDocumentOperationsConfig (curFile, options, cb) {
    const codePayload = {
        inputs: [
            {
                href: curFile.path,
                storage: curFile.storage
            }
        ],
        options: {
            layers: options.layers
        },
        outputs: [
            {
                href: `${getTempDirectory()}/${uuid()}/${curFile.shortName}_preview.psd`,
                storage: 'adobe',
                type: 'vnd.adobe.photoshop',
                overwrite: true
            }
        ]
    }
    if (options.fonts) { codePayload.options.fonts = options.fonts }
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/documentOperations`,
        method: 'POST',
        headers: getHeaders(),
        timeout: 90000,
        body: JSON.stringify(codePayload),
        payload: codePayload
    }
    cb(config)
}
export function postDocumentOperations (curFile, layers, config, cb) {
    try {
        apiCall(config, (id, json) => {
            if (id) {
                getStatus(id, config, (res, error, errResponse) => {
                    if (error) {
                        // throw(error, `${error} - ${JSON.stringify(curFile)}`);
                        // new Error(error);
                        cb(null, new Error(error))
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                        // throw(error, errMsg);
                        // new Error(errMsg);
                        cb(null, new Error(errMsg))
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        const preResponse = JSON.parse(JSON.stringify(res))
                        res.outputs[0]._links.renditions.forEach(rendition => {
                            rendition.path = rendition.href
                            getDocumentManifest(rendition, files => {
                                if (files) {
                                    cb(preResponse, null, files)
                                } else {
                                    error(__('browser_cc_get_document_manifest_error'), {
                                        actionLabel: 'Try again',
                                        onClose: () => console.log('close'),
                                        timeout: 5000
                                    })
                                }
                            })
                        })
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                cb(null, new Error(`${curFile} - ${errMsg} - ${json}`))
            }
        })
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}

export function getRenditionCreate (file, cb) {
    if (file.type === 'text/plain' || file.type === 'text/csv' || file.type === 'application/octet-stream' || file.type === 'application/json') {
        cb(null, null)
    } else if (file.type === 'application/illustrator' || file.type === 'application/pdf' || file.type === 'image/svg+xml') {
        cb(null, file.url, file.url)
    } else {
        try {
            const codePayload = {
                inputs: [
                    {
                        href: file.url || file.href,
                        storage: file.storage
                    }
                ],
                outputs: [
                    {
                        href: `${getTempDirectory()}/${uuid()}/${file.shortName}_rendition.png`,
                        // href: `files/output/${uuid()}/temp_rendition.png`,
                        storage: 'adobe',
                        type: 'image/png',
                        overwrite: true
                    }
                ]
            }
            const config = {
                url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/renditionCreate`,
                method: 'POST',
                timeout: 60000,
                headers: getHeaders(),
                body: JSON.stringify(codePayload)
            }
            apiCall(config, (id, json) => {
                if (id) {
                    getStatus(id, config, (res, error, errResponse) => {
                        if (error) {
                            cb(error, null)
                            // error(`${__('error_msg_title')}: ${error}`, {
                            //   timeout: 0
                            // });
                        } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                            const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                            console.log(errMsg)
                            cb(error, null)
                            // error(`${__('error_msg_title')}: ${errMsg}`, {
                            //   timeout: 0
                            // });
                        } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                            res.outputs[0]._links.renditions.forEach(rendition => {
                                cb(null, `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${rendition.href}/:rendition;size=300?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                                    `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${rendition.href}/:rendition;size=0?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`)
                                // return `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${rendition.href}/:rendition;size=300?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`;
                                // files.push({
                                //     inputFile: curFile,
                                //     name: getFilename(rendition.href),
                                //     shortName: getFilenameWithoutExt(rendition.href),
                                //     path: rendition.href,
                                //     outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${rendition.href}/:rendition;size=300?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                                //     output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${rendition.href}/:rendition;size=0?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                                //     outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${rendition.href}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                                // })
                            })
                        }
                    })
                } else {
                    const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                    console.log(errMsg)
                    error(`${__('error_msg_title')}: ${errMsg}`, {
                        timeout: 0
                    })
                }
            })
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }
}

export async function getRenditionCreateWithUtilsStorage (file, smallRenditionSize, largeRenditionSize, cb) {
    if (file.type === 'text/plain' || file.type === 'text/csv' || file.type === 'application/octet-stream' || file.type === 'application/json') {
        cb(null, null)
    } else if (file.type === 'application/illustrator' || file.type === 'application/pdf' || file.type === 'image/svg+xml') {
        cb(null, file.url, file.url)
    } else {
        try {
            const codePayload = {
                inputs: [
                    {
                        href: file.url || file.href || file.path,
                        storage: file.storage
                    }
                ],
                outputs: []
            }
            const utilsResponse = await generatePresignedUrlsFromUtils(2)
            if (smallRenditionSize) {
                codePayload.outputs.push({
                    href: utilsResponse.data.outputs.urls[0].put,
                    storage: 'external',
                    type: 'image/png',
                    width: smallRenditionSize,
                    overwrite: true
                })
            }
            if (largeRenditionSize || largeRenditionSize === 0) {
                codePayload.outputs.push(
                    {
                        href: utilsResponse.data.outputs.urls[1].put,
                        storage: 'external',
                        type: 'image/png',
                        width: largeRenditionSize,
                        overwrite: true
                    })
            }
            const config = {
                url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/renditionCreate`,
                method: 'POST',
                timeout: 60000,
                headers: getHeaders(),
                body: JSON.stringify(codePayload)
            }
            await apiCall(config, async (id, json) => {
                if (id) {
                    await getStatus(id, config, async (res, error, errResponse) => {
                        if (error) {
                            cb(error, null)
                        } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                            const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                            console.log(errMsg)
                            cb(error, null)
                        } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                            // res.outputs[0]._links.renditions.forEach(rendition => {
                            cb(null, `${utilsResponse.data.outputs.urls[0].get}`, `${utilsResponse.data.outputs.urls[1].get}`)
                            // const renditionSignedUrl = `${file.renditionGet}`
                            // return renditionSignedUrl
                            // })
                        }
                    })
                } else {
                    const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                    console.log(errMsg)
                    error(`${__('error_msg_title')}: ${errMsg}`, {
                        timeout: 0
                    })
                }
            })
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }
}

export function getRandomInt (min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export function getStorage (file) {
    if (file.storage) {
        switch (file.storage) {
        case 'adobe':
            return 'adobe'
        default:
            return 'external'
        }
    }
    return 'adobe'
}

export function getLayerTypeIcon (layer) {
    switch (layer.type) {
    case 'layerSection':
        return <Folder size="S" />
    case 'textLayer':
        return <Text size="S" />
    case 'smartObject':
        if (layer.thumbnail) {
            const ext = getExtensionFromPathWithParameters(layer.thumbnail)
            if (!ext.match(/tiff/i)) {
                return <div className="sc-layer-thumbnail-container">
                    <img className="adobe-skycity-layer-thumbnail" alt="thumbnail" src={layer.thumbnail} />
                    <div className="adobe-skycity-layer-thumbnail-pssmartobject"><Document className="sc-layer-thumbnail-icon-doc" size="XS" /></div>
                </div>
            }
        }
        break
    default:
        if (layer.children) {
            return <Folder size="S" />
        }
        if (layer.thumbnail) {
            const ext = getExtensionFromPathWithParameters(layer.thumbnail)
            if (!ext.match(/tiff/i)) {
                return <img className="adobe-skycity-layer-thumbnail" alt="thumbnail" src={layer.thumbnail} />
            }
        }
        return <Image size="S" />
    }
}

export async function getOptionsOutput (curFile, outputOptions, iccProfileOption) {
    const outputs = []
    let quality = null
    for (const outputOption of outputOptions) {
        if (outputOption.selected === true) {
            let imageType = outputOption.type
            if (imageType === 'image/vnd.adobe.photoshop') {
                imageType = 'vnd.adobe.photoshop'
            }
            const obj = {
                storage: 'external',
                type: imageType,
                overwrite: true,
                width: outputOption.width
            }
            switch (outputOption.format) {
            case 'jpeg':
                if (outputOption.quality.find) {
                    quality = outputOption.quality.find(quality => quality.selected === true).value
                }
                if (quality === null || quality === undefined) {
                    quality = outputOption.quality
                }
                obj.quality = quality
                // obj.href = `${getTempDirectory()}/${uuid()}/${curFile.shortName}_${outputOption.width}_${obj.quality}.${outputOption.format}`
                break
            case 'png':
                obj.compression = (outputOption.compression.find && outputOption.compression.find(compression => compression.selected === true).value) || outputOption.compression
                // obj.href = `${getTempDirectory()}/${uuid()}/${curFile.shortName}_${outputOption.width}_${obj.compression}.${outputOption.format}`
                break
            default:
                    // obj.href = `${getTempDirectory()}/${uuid()}/${curFile.shortName}_${outputOption.width}.${outputOption.format}`
            }
            if (iccProfileOption && iccProfileOption.selected) {
                const selectedImageMode = iccProfileOption.imageMode.filter(mode => mode.selected)[0].value
                if (iccProfileOption.profileName.selected) {
                    const selectedProfileNames = iccProfileOption.profileName[selectedImageMode]
                    if (selectedProfileNames) {
                        const selectedProfileName = selectedProfileNames.find(mode => mode.selected)
                        obj.iccProfile = {
                            imageMode: selectedImageMode,
                            profileName: selectedProfileName.value
                        }
                    }
                } else if (iccProfileOption.profileFile.selected) {
                    obj.iccProfile = {
                        imageMode: selectedImageMode,
                        input: iccProfileOption.profileFile.input
                    }
                }
            }
            const utilsResponse = await generatePresignedUrlsFromUtils(1)
            obj.href = utilsResponse.data.outputs.urls[0].put
            obj.getHref = utilsResponse.data.outputs.urls[0].get
            outputs.push(obj)
        }
    }
    return outputs
    // outputOptions.forEach(async (outputOption) => {
    //     if (outputOption.selected === true) {
    //         let imageType = outputOption.type
    //         if (imageType === 'image/vnd.adobe.photoshop') {
    //             imageType = 'vnd.adobe.photoshop'
    //         }
    //         const obj = {
    //             storage: 'external',
    //             type: imageType,
    //             overwrite: true,
    //             width: outputOption.width
    //         }
    //         switch (outputOption.format) {
    //         case 'jpeg':
    //             if (outputOption.quality.find) {
    //                 quality = outputOption.quality.find(quality => quality.selected === true).value
    //             }
    //             if (quality === null || quality === undefined) {
    //                 quality = outputOption.quality
    //             }
    //             obj.quality = quality
    //             //obj.href = `${getTempDirectory()}/${uuid()}/${curFile.shortName}_${outputOption.width}_${obj.quality}.${outputOption.format}`
    //             break
    //         case 'png':
    //             obj.compression = (outputOption.compression.find && outputOption.compression.find(compression => compression.selected === true).value) || outputOption.compression
    //             //obj.href = `${getTempDirectory()}/${uuid()}/${curFile.shortName}_${outputOption.width}_${obj.compression}.${outputOption.format}`
    //             break
    //         default:
    //             //obj.href = `${getTempDirectory()}/${uuid()}/${curFile.shortName}_${outputOption.width}.${outputOption.format}`
    //         }
    //         if (iccProfileOption && iccProfileOption.selected) {
    //             const selectedImageMode = iccProfileOption.imageMode.filter(mode => mode.selected)[0].value
    //             if (iccProfileOption.profileName.selected) {
    //                 const selectedProfileNames = iccProfileOption.profileName[selectedImageMode]
    //                 if (selectedProfileNames) {
    //                     const selectedProfileName = selectedProfileNames.find(mode => mode.selected)
    //                     obj.iccProfile = {
    //                         imageMode: selectedImageMode,
    //                         profileName: selectedProfileName.value
    //                     }
    //                 }
    //             } else if (iccProfileOption.profileFile.selected) {
    //                 obj.iccProfile = {
    //                     imageMode: selectedImageMode,
    //                     input: iccProfileOption.profileFile.input
    //                 }
    //             }
    //         }
    //         outputs.push(obj)
    //     }
    // })
}

export async function getOptionsOnlyOutput (curFile, outputOptions) {
    const outputs = []
    let quality = null
    for (const outputOption of outputOptions) {
        if (outputOption.selected === true) {
            let imageType = outputOption.type
            if (imageType === 'image/vnd.adobe.photoshop') {
                imageType = 'vnd.adobe.photoshop'
            }
            const obj = {
                storage: 'external',
                type: imageType,
                overwrite: true,
                width: outputOption.width
            }
            switch (outputOption.format) {
            case 'jpeg':
                if (outputOption.quality.find) {
                    quality = outputOption.quality.find(quality => quality.selected === true).value
                }
                if (quality === null || quality === undefined) {
                    quality = outputOption.quality
                }
                obj.quality = quality
                // obj.href = `${getTempDirectory()}/${uuid()}/${curFile.shortName}_${outputOption.width}_${obj.quality}.${outputOption.format}`
                break
            case 'png':
                obj.compression = (outputOption.compression.find && outputOption.compression.find(compression => compression.selected === true).value) || outputOption.compression
                // obj.href = `${getTempDirectory()}/${uuid()}/${curFile.shortName}_${outputOption.width}_${obj.compression}.${outputOption.format}`
                break
            default:
                // obj.href = `${getTempDirectory()}/${uuid()}/${curFile.shortName}_${outputOption.width}.${outputOption.format}`
            }
            const utilsResponse = await generatePresignedUrlsFromUtils(1)
            obj.href = utilsResponse.data.outputs.urls[0].put
            obj.getHref = utilsResponse.data.outputs.urls[0].get
            outputs.push(obj)
        }
    }
    return outputs
}

export function getOptionsOutputLocale (curFile, outputOptions, locale) {
    const outputs = []
    outputOptions.forEach(outputOption => {
        if (outputOption.selected === true) {
            let imageType = outputOption.type
            if (imageType === 'image/vnd.adobe.photoshop') {
                imageType = 'vnd.adobe.photoshop'
            }
            const obj = {
                storage: 'adobe',
                type: imageType,
                overwrite: true,
                width: outputOption.width
            }
            switch (outputOption.format) {
            case 'jpeg':
                obj.quality = outputOption.quality.find(quality => quality.selected === true).value
                obj.href = `${getTempDirectory()}/${uuid()}/${curFile.shortName}_${outputOption.width}_${obj.quality}_${locale}.${outputOption.format}`
                break
            case 'png':
                obj.compression = outputOption.compression.find(compression => compression.selected === true).value
                obj.href = `${getTempDirectory()}/${uuid()}/${curFile.shortName}_${outputOption.width}_${obj.compression}_${locale}.${outputOption.format}`
                break
            default:
                obj.href = `${getTempDirectory()}/${uuid()}/${curFile.shortName}_${outputOption.width}_${locale}.${outputOption.format}`
            }
            console.log(`obj.href: ${obj.href}`)
            outputs.push(obj)
        }
    })
    return outputs
}

export async function getOptionsOutputImagecutout (curFile) {
    const utilsResponse = await generatePresignedUrlsFromUtils(1)
    const obj = {
        storage: 'external',
        href: utilsResponse.data.outputs.urls[0].put,
        getHref: utilsResponse.data.outputs.urls[0].get,
        mask: {
            format: 'soft'
        }
    }
    return obj
}

export function getOutputDirectory (curFile) {
    return `${getTempDirectory()}/${uuid()}`
}

export function getLrPresets (files) {
    const presets = files.map(curFile => {
        return {
            href: curFile.path,
            storage: getStorage(curFile)
        }
    })
    return presets
}

export function getLrApplyEditOptions (lrApplyEditOptions) {
    const options = {}
    lrApplyEditOptions.forEach(option => {
        options[option.label] = option.value
    })
    return options
}

export function getLrAutostraightenOptions (lrAutostraightenOptions) {
    const options = {}
    lrAutostraightenOptions.forEach(option => {
        if (option.selected) {
            options.uprightMode = option.value
        }
    })
    return options
}

export function getUniversalRefinementModelOptions (ssRefinementModelOptions) {
    let model = 'generic'
    ssRefinementModelOptions.forEach(option => {
        if (option.selected) {
            model = option.value
        }
    })
    return model
}

export function getImageLayersToInsert (inputSecondFiles, insertLayerAsSmartObject) {
    let layerType = 'layer'
    if (insertLayerAsSmartObject) {
        layerType = 'smartObject'
    }
    const options = inputSecondFiles.map(curSecondFile => {
        const option = {
            add: {
                insertTop: true
            },
            type: layerType,
            input: {
                href: curSecondFile.path || curSecondFile.href,
                storage: getStorage(curSecondFile)
            }
        }
        return option
    })
    return options
}

export function getActionFileToPlay (inputSecondFiles, psActionFile) {
    const actions = inputSecondFiles.map(curSecondFile => {
        const file = curSecondFile.input || curSecondFile
        const inputObj = {
            href: file.path || file.href,
            storage: getStorage(file)
        }
        if (psActionFile && psActionFile.selected && psActionFile.actions.length > 0) {
            const selectedActionName = psActionFile.actions.filter(action => action.selected)[0].value
            if (selectedActionName) {
                inputObj.actionName = selectedActionName
            }
        }
        return inputObj
    })
    return actions
}

export function getInputFileFont (inputSecondFiles) {
    const fonts = inputSecondFiles.map(curSecondFile => {
        const font = {
            href: curSecondFile.path,
            storage: getStorage(curSecondFile)
        }
        return font
    })
    return fonts
}

export function getOptionsLayers (curFile, layerOptions) {
    const options = []
    layerOptions.forEach(layerOption => {
        if (layerOption.selected === true) {
            const obj = {
                add: {
                    insertTop: true
                },
                type: 'adjustmentLayer'
            }
            switch (layerOption.type) {
            case 'brightnessContrast':
            case 'exposure':
                obj.adjustments = {}
                obj.adjustments[layerOption.type] = {}
                layerOption.props.forEach(option => {
                    obj.adjustments[layerOption.type][option.type] = layerOption.props.find(prop => prop.type === option.type).value
                })
                break
            case 'hueSaturation':
                obj.adjustments = {
                    hueSaturation: {
                        colorize: layerOption.channels.find(channel => channel.type === 'colorize').value,
                        channels: [
                            {
                                channel: 'master',
                                hue: layerOption.channels.find(channel => channel.type === 'hue').value,
                                saturation: layerOption.channels.find(channel => channel.type === 'saturation').value,
                                lightness: layerOption.channels.find(channel => channel.type === 'lightness').value
                            }
                        ]
                    }
                }
                break
            case 'colorBalance':
                obj.adjustments = {}
                obj.adjustments[layerOption.type] = {}
                obj.adjustments[layerOption.type].preserveLuminosity = layerOption.props.find(prop => prop.type === 'preserveLuminosity').value
                layerOption.props.forEach(option => {
                    if (option.field === 'array') {
                        obj.adjustments[layerOption.type][option.type] = [
                            option.colors.find(color => color.type === 'cyan').value,
                            option.colors.find(color => color.type === 'magenta').value,
                            option.colors.find(color => color.type === 'yellow').value
                        ]
                    }
                })
                break
            default:
            }
            options.push(obj)
        }
    })
    return options
}

export const getLocalStorageItem = (keyName) => {
    if (window.localStorage.getItem('comAdobediceSkycity')) {
        const comAdobediceSkycity = JSON.parse(window.localStorage.getItem('comAdobediceSkycity'))
        return comAdobediceSkycity[keyName] || null
    }
    return null
}

export const setLocalStorageItem = (keyName, keyValue) => {
    const comAdobediceSkycity = window.localStorage.getItem('comAdobediceSkycity')
    if (!comAdobediceSkycity) {
        const ititComAdobediceSkycity = {
            app: 'com.adobedice.skycity'
        }
        window.localStorage.setItem('comAdobediceSkycity', JSON.stringify(ititComAdobediceSkycity))
    }
    const comAdobediceSkycityObj = JSON.parse(window.localStorage.getItem('comAdobediceSkycity'))
    comAdobediceSkycityObj[keyName] = keyValue
    window.localStorage.setItem('comAdobediceSkycity', JSON.stringify(comAdobediceSkycityObj))
    return comAdobediceSkycityObj[keyName] || null
}

export const resetLocalStorageItem = (keyName) => {
    try {
        window.localStorage.removeItem(keyName)
    } catch (e) {
        alert(e)
    }
}

export const removeLocalStorageItem = (keyName) => {
    const comAdobediceSkycityObj = JSON.parse(window.localStorage.getItem('comAdobediceSkycity'))
    if (comAdobediceSkycityObj) {
        delete comAdobediceSkycityObj[keyName]
    }
    window.localStorage.setItem('comAdobediceSkycity', JSON.stringify(comAdobediceSkycityObj))
}

export const downloadFile = (href, fileName) => {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = href
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

export const findCommonType = (arr1, arr2) => {
    for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
            if (arr1[i].type === arr2[j]) {
                return true
            }
        }
    }
    return false
}

export const copyText = (text) => {
    let newLine = ''
    var lines = text.split('\n')
    for (var i = 0; i < lines.length; i++) {
        const regex = /(^#|\*\*\*\*\*)/ig
        // const regex = /(^\#)/ig;
        if (!lines[i].match(regex)) {
            newLine += `${lines[i]}\n`
        }
    }
    const el = document.createElement('textarea')
    el.value = newLine
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    return newLine
}

export const getBase64 = (curFile, cb) => {
    try {
        if (curFile.storage === 'adobe') {
            getFile(curFile, raw => {
                if (raw) {
                    cb(`data:image/png;base64,${raw}`)
                } else {
                    error(`${__('error_msg_title')}: Failed to get raw image.`, {
                        timeout: 0
                    })
                    cb(false)
                }
            })
        } else {
            const image2base64 = require('image-to-base64')
            image2base64(curFile.signedUrl)
                .then(
                    (response) => {
                        cb(`data:image/png;base64,${response}`)
                    }
                )
                .catch(
                    (err) => {
                        console.log(err)
                        error(`${__('error_msg_title')}: ${err}`, {
                            timeout: 0
                        })
                        cb(false)
                    }
                )
        }
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}

export const getTempDirectory = () => {
    const fUrl = new URL(document.location.href).searchParams.get('dir')
    return fUrl ? `files/${fUrl}` : process.env.REACT_APP_CONFIG_APP_TEMPDIR
}

export const showPocMenu = () => {
    // const fUrl = new URL(document.location.href).searchParams.get('poc')
    // return fUrl ? true : false
}

export const getSignedUlr = (file, cb) => {
    const regex = /(^files\/|^temp\/skycity_temporary_directory)/ig
    const apikeyRegex = /(api_key)/ig

    if (file.path && file.path.match(regex)) {
        getRaw(file, raw => {
            cb(raw.presigned_href || new Error())
        })
    } else if (file.outputs && !file.outputs.match(apikeyRegex)) {
        const url = `${file.outputs}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        cb(url)
    } else {
        cb(file.path)
    }
}

export const runHealthCheck = (config, cb) => {
    if (config.url.match(/https:\/\//)) {
        const request = require('request')
        try {
            request(config, (error, response, body) => {
                if (error) {
                    console.log(`error: ${error}`)
                    cb(null, error.message)
                } else if (body) {
                    if (response && response.statusCode >= 400) {
                        cb(null, body)
                    } else {
                        cb(body)
                    }
                } else {
                    cb(null, body)
                }
            })
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            cb(null, e)
        }
    } else {
        var http = require('http')
        var options = { method: 'HEAD', host: config.url, port: 80, path: '/' }
        var req = http.request(options, (r) => {
            alert(JSON.stringify(r.headers))
        })
        req.end()
    }
}

export const getPreviewFileDocumentManifest = (previewFiles) => {
    if (previewFiles && previewFiles[0] && previewFiles[0].documentManifest) {
        if (previewFiles[0].documentManifest.layers) {
            return previewFiles[0].documentManifest.layers
        }
        if (previewFiles[0].documentManifest.outputs[0] && previewFiles[0].documentManifest.outputs[0].layers) {
            return previewFiles[0].documentManifest.outputs[0].layers
        }
    }
    return []
}

export const getFileViewOption = () => {
    const vo = getLocalStorageItem('fileViewOption') || setLocalStorageItem('fileViewOption', __('default_file_view_option'))
    return vo
}

export const uploadTempAsset = () => {
    try {
        const regex = /^temp$/g
        if (!getTempDirectory().match(regex)) {
            return
        }
        const config = {
            headers: getSenseiHeaders(),
            timeout: 300000
        }
        const url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getTempDirectory()}`
        axios.head(url, config)
            .then((response) => {
            // handle success
            // console.log(`uploadTempAsset - response: ${JSON.stringify(response)}`)
                console.log(`temp - head ${response.status}`)
                createTempAsset()
            })
            .catch((error) => {
            // handle error
            // alert(error);
            // console.log(`uploadTempAsset error: ${error}`)
                console.log(`uploadTempAsset - error: ${JSON.stringify(error)}`)
                if (uploadTempAssetNum > 0) {
                    uploadTempAssetNum--
                    uploadTempAsset()
                }
            })
            .then(() => {
            // console.log(`always executed`);
            // always executed
            })
    } catch (e) {
        console.log(e)
    }
}

export function getAssetUrn (assetURL) {
    const config = {
        headers: getSenseiHeaders(),
        timeout: 300000
    }
    return axios.head(assetURL, config)
        .then((response) => {
            // handle success
            return response.headers['x-resource-urn']
        })
        .catch((error) => {
        // handle error
            console.log(`getAssetUrn - error: ${JSON.stringify(error)}`)
        })
}

export const createTempAsset = () => {
    const uuidNum = uuid()
    const fileName = `${uuidNum}.png`
    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n) {
            u8arr[n] = bstr.charCodeAt(n)
            n -= 1
        }
        return new File([u8arr], filename, { type: mime })
    }
    const file = dataURLtoFile('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAHElEQVQYlWNkYGD4z0AEYCJGEcOoQuooZGBgAACmFwETVLv3XgAAAABJRU5ErkJggg==', fileName)
    const data = new FormData()
    data.append('img', file, fileName)
    const config = {
        headers: getSenseiHeaders(),
        timeout: 300000
    }
    const url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getTempDirectory()}/${file.name}`
    axios.put(url, data, config)
        .then((response) => {
        // handle success
        // console.log(`createTempAsset - response: ${JSON.stringify(response)}`)
            console.log(`temp - put ${response.status}`)
        // console.log(`temp/ ready`);
        })
        .catch((error) => {
        // handle error
        // alert(error);
        // console.log(`uploadTempAsset error: ${error}`);
        // console.log(`temp/ not ready`);
            console.log(`createTempAsset - error: ${JSON.stringify(error)}`)
            if (uploadTempAssetNum > 0) {
                uploadTempAssetNum--
                createTempAsset()
            }
        })
        .then(() => {
        // console.log(`always executed`);
        // always executed
        })
}

export const getEncodedURI = (path) => {
    if (path) {
        let paths = []
        let domain = ''
        if (path.match(/http/)) {
            const urls = path.split('//')
            domain = `${urls[0]}//`
            paths = urls[1].split(/\//)
            domain += `${paths.shift()}/`
        } else {
            paths = path.split('/')
        }
        for (var i = 0; i < paths.length; i++) {
            paths[i] = encodeURIComponent(paths[i])
        }
        return `${domain}${paths.join('/')}`
    } else {
        return ''
    }
}

export function getCodeSnippetText (currentPage, codes) {
    let text = ' #!/bin/bash\n\n'
    text += '# ************************************\n'
    text += '# Check if jq is installed\n'
    text += '# ************************************\n'
    text += 'if [ ! `which jq` ]; then\n'
    text += '\techo "************************************"\n'
    text += '\techo "Please install jq: brew install jq"\n'
    text += '\techo "************************************"\n'
    text += '\texit 1\n'
    text += 'fi\n\n'
    text += '# ************************************\n'
    text += '# Set variables\n'
    text += '# ************************************\n'
    text += '# set -x\n'
    // text += `token='${window.adobeIMS.getAccessToken().token}'\n`
    // text += `apiKey='${process.env.REACT_APP_CONFIG_APP_KEY}'\n`
    text += `${getCodeLineToken()}`
    text += `${getCodeLineApiKey(process.env.REACT_APP_CONFIG_APP_KEY)}`
    text += `endpoint='${codes[currentPage - 1].codeEndpoint}'\n`
    text += `method='${codes[currentPage - 1].codeMethod}'\n\n`
    text += `payload='${JSON.stringify(codes[currentPage - 1].codePayload, null, 2)}'\n\n`
    text += '# ************************************\n'
    text += '# Call API\n'
    text += '# ************************************\n'
    text += 'res=$(curl -k -Ss -H "Authorization: Bearer $token" -H "Content-Type:application/json" -H "x-api-key: $apiKey" -X "$method" -d "$payload" "$endpoint")\n'
    text += 'myerror=$(echo $res | jq -r .code)\n'
    text += 'if [ $myerror != "null" ]; then\n'
    text += '\techo "ERROR: $res"\n'
    text += '\texit 1\n'
    text += 'fi\n'
    text += 'jobid=$(echo $res | jq -r ._links.self.href)\n'
    text += 'echo "JOBID: $jobid"\n\n'
    text += '# ************************************\n'
    text += '# Check Status\n'
    text += '# ************************************\n'
    text += 'while [ "x$jobstatus" != "xsucceeded" ] && [ "x$jobstatus" != "xfailed" ]; do\n'
    text += '\toutput=$(curl -k -Ss -H "Authorization: Bearer $token" -H "Content-Type:application/json" -H "x-api-key: $apiKey" -X GET "$jobid" | jq -r \'.outputs[0]\')\n'
    text += '\tjobstatus=$(echo $output | jq -r \'.status\')\n'
    text += '\techo "JOBSTATUS: $jobstatus"\n'
    text += 'done\n\n'
    text += '# ************************************\n'
    text += '# Result\n'
    text += '# ************************************\n'
    text += 'echo "************************************"\n'
    text += 'echo "RESULT"\n'
    text += 'echo ""\n'
    text += 'echo $output | jq\n'
    text += 'echo "************************************"\n'
    return text
}

export function downloadScript (e, val) {
    const text = getCodeSnippetText(1, [val.config])
    const data = new Blob([text], { type: 'text/plain' })
    const textFile = window.URL.createObjectURL(data)
    downloadFile(textFile, __('batch_filename'))
}

export async function getSignedUrl (input, bucket) {
    const bucketName = bucket || 'cis-skycity-asset'
    AWS.config.region = 'us-west-2'
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-west-2:327297b0-db7b-450d-885c-a772c340e2d3'
    })
    const s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        params: { Bucket: bucketName }
    })

    const signedUrl = new Promise((resolve, reject) => {
        const params = {
            Bucket: bucketName,
            Key: input,
            Expires: 24 * 60 * 60
        }
        s3.getSignedUrl('getObject', params, (err, url) => {
            if (!err && url) {
                resolve(url)
            } else {
                reject(err)
            }
        })
    })
    const result = await signedUrl
    return result
}

export function downloadOutputFile (e, file) {
    try {
        const regex = /^data:image\/png;base64/ig
        const a = document.createElement('a')
        switch (e) {
        case 'download':
            if (file.path && file.path.match(regex)) {
                const a = document.createElement('a')
                a.target = '_blank'
                a.href = file.path
                a.download = file.name
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                break
            }
            a.target = '_blank'
            a.href = file.outputToDownload
            a.download = file.name
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            break
        default:
        }
    } catch (e) {
        alert(e)
    }
}

export async function getExternalInputFile (inputFile) {
    const singedUrl = await getSignedUrl(inputFile)
    return {
        href: singedUrl,
        storage: 'external',
        type: getExtensionFromPath(inputFile)
    }
}

export async function getExternalInputSecondFiles (inputSecondFiles) {
    const singedUrl = await getSignedUrl(inputSecondFiles)
    return [
        {
            href: singedUrl,
            storage: 'external',
            type: getExtensionFromPath(inputSecondFiles)
        }
    ]
}

export async function getTestCaseOutputOptions (options, type) {
    switch (type) {
    case 'jpg':
    case 'jpeg':
        return [
            {
                selected: true,
                storage: 'adobe',
                type: 'image/jpeg',
                overwrite: true,
                width: 0,
                quality: 1,
                href: `${getTempDirectory()}/${uuid()}.jpeg`,
                iccProfile: {
                    imageMode: 'grayscale',
                    input: await getExternalInputFile(options.iccFile)
                }
            }
        ]
    case 'png':
        return [
            {
                selected: true,
                storage: 'adobe',
                type: 'image/png',
                overwrite: true,
                width: 100,
                compression: 'large',
                href: `${getTempDirectory()}/${uuid()}.png`
            }
        ]
    case 'psd':
        return [
            {
                selected: true,
                storage: 'adobe',
                type: 'vnd.adobe.photoshop',
                overwrite: true,
                width: 50,
                href: `${getTempDirectory()}/${uuid()}.psd`
            }
        ]
    default:
        alert('ERROR: No Type Found')
    }
}

export function getStorageTypes (storageTypes) {
    const types = storageTypes.filter(type => type.selected)
    return types
}

export const getCodeLineToken = () => {
    if (getLocalStorageItem('hideTokenInCode') === 'true') {
        return 'token=\'\'\n'
    }
    return `token='${window.adobeIMS.getAccessToken().token}'\n`
}

export const getCodeLineApiKey = (apiKey) => {
    if (getLocalStorageItem('hideTokenInCode') === 'true') {
        return 'apiKey=\'\'\n'
    }
    return `apiKey='${apiKey}'\n`
}

export const getCCStorageErrorMsg = (res) => {
    error(__('no_cc_storage_access'), {
        actionLabel: __('info_how_to_create_stage_account'),
        onAction: (e) => goToUrlTarget(__('info_how_to_create_stage_account_url')),
        timeout: 0
    })
}

export async function fileUpload (fileToUpload, outputFileName) {
    const url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getTempDirectory()}/${outputFileName}`
    const formData = new FormData()
    formData.append('file', fileToUpload)
    const config = {
        headers: getHeaders(),
        onUploadProgress: (e) => {
            const currentProgress = (parseInt(e.loaded) / parseInt(e.total) * 100).toFixed(0)
            const curFile = {}
            curFile.name = fileToUpload.name
            curFile.progress = currentProgress
        },
        timeout: 300000
    }

    return await axios.put(url, formData, config)
}

export async function fileUploadToUtilsService (fileToUpload, outputFileUrl) {
    const formData = new FormData()
    formData.append('file', fileToUpload)
    const configS3PUT = {
        headers: {
            'Content-Type': 'application/octet-stream',
            'Access-Control-Allow-Origin': '*'
        },
        onUploadProgress: (e) => {
            const currentProgress = (parseInt(e.loaded) / parseInt(e.total) * 100).toFixed(0)
            const curFile = {}
            curFile.name = fileToUpload.name
            curFile.progress = currentProgress
        },
        timeout: 300000
    }
    return await axios.put(outputFileUrl, fileToUpload, configS3PUT)
}

export function getOutputFileName (curFile, outputFile) {
    let outputFileName
    const format = getFileType(outputFile.type)
    switch (format) {
    case 'jpeg':
        outputFileName = `${curFile.shortName}_${outputFile.width}_${outputFile.quality}.${format}`
        break
    case 'png':
        outputFileName = `${curFile.shortName}_${outputFile.width}_${outputFile.compression}.${format}`
        break
    default:
        outputFileName = `${curFile.shortName}_${outputFile.width}.${format}`
    }
    return outputFileName
}

export function isSupportedFileForThumnail (imageType) {
    return !!((imageType === 'jpg' || imageType === 'jpeg' || imageType === 'png' || imageType === 'psd' || imageType === 'tif' || imageType === 'tiff'))
}

export function getToken () {
    const el = document.createElement('textarea')
    el.value = window.adobeIMS.getAccessToken().token
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    success('Your token is copied.', {
        actionLabel: 'Log in console',
        onAction: (e) => console.log(window.adobeIMS.getAccessToken().token),
        timeout: 5000
    })
}
