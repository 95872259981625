import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import { ssApiSyncCall } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders
} from '../../lib/skycity.js'

import axios from 'axios'
import {
    exploreIndexDocument,
    exploreHomeDirectory,
    uploadFile,
    retrieveRendition

} from '../../lib/acpApi.js'
const LinkHeader = require('http-link-header')
const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)
    async function start (curFile) {
        /* *********************************** */
        const files = []
        //  replace with presignedUrl
        const imgFilePath = curFile.path
        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'stuffsegmentation',
                'sensei:invocation_mode': 'synchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Classification:autocrop:Service-3bc07ddc146a48a3a00bfedff5cd40b5'
                        },
                        'sensei:inputs': {
                            image: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': imgFilePath
                            }
                        },
                        'sensei:outputs': {
                            masks: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'masks'
                            },
                            stuff_metadata: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'stuff_metadata'
                            },
                            metadata: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'metadata'
                            }
                        }
                    }
                ]
            }
        }
        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }
        /* *********************************** */

        dispatch({ type: 'set-codes', payload: { config: config } })

        const responseBody = await ssApiSyncCall(config)
        const contentAnalyzerResponse = responseBody.get('contentAnalyzerResponse')
        const response = {}
        response.metadata = JSON.parse(responseBody.get('metadata'))
        response.stuff_metadata = JSON.parse(responseBody.get('stuff_metadata'))
        response.sensei_metadata = JSON.parse(contentAnalyzerResponse)
        const masks = response.sensei_metadata.statuses[0].invocations[0]['sensei:outputs'].masks
        // 1. Explore the Index Document and get the Primary URL of the home Directory
        const homeDirectoryPrimaryUrl = await exploreIndexDocument()
        // 2. Explore the home Directory and get the Create URL of the Cloud Documents Directory
        const exploreHomeDirectoryResponse = await exploreHomeDirectory(
            homeDirectoryPrimaryUrl
        )
        const cloudDocumentsCreateUrl = exploreHomeDirectoryResponse.createLink
        for (let i = 0; i < masks.length; ++i) {
            const output = responseBody.get(masks[i]['sensei:multipart_field_name'])
            const blob = output.slice(0, output.size, 'image/png')

            const outputFileName = `stuffsegmentation_${uuid()}_${curFile.shortName}.png`
            const newFile = new File([blob], outputFileName, { type: 'image/png' })
            const outputHref = `${getTempDirectory()}/${outputFileName}`
            const fileUploaded = await uploadFile(
                cloudDocumentsCreateUrl,
                newFile
            )
            const uploadlinks = LinkHeader.parse(fileUploaded.headers.get('link'))
            const primaryUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/primary'))[0].uri
            const renditionUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/rendition'))[0].uri
            const largeRendition = await retrieveRendition(
                renditionUrl,
                0,
                'image/png'
            )
            files.push({
                inputFile: curFile,
                name: getFilename(outputHref),
                shortName: getFilenameWithoutExt(outputHref),
                path: outputHref,
                outputThumbnail: largeRendition,
                output: largeRendition,
                outputToDownload: `${getEncodedURI(primaryUrl)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
            })
        }

        dispatch({
            type: 'skycity-success',
            payload: {
                files: files,
                response: response,
                loading: false,
                wait: false
            }
        })
    }

    async function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            const funcArray = []
            inputFiles.forEach(inputFile => {
                funcArray.push(start(inputFile))
            })

            await axios.all(funcArray)
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        } finally {
            console.log('finally...')
            dispatch({
                type: 'sensei-end-wait'
            })
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function checkFileType (type) {
        if (type === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    function errorOccured (errMsg, res) {
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={async e => await run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
