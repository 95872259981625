import React from 'react'
import Key from '@react/react-spectrum/Icon/Key'
import { getToken } from '../../lib/skycity'

export default function TokenLink ({ className }) {
    return (
        <div className="adobe-skycity-header-icon-div" onClick={ e => getToken(e) }>
            <Key className="adobe-skycity-header-icon" size="M"/>
        </div>
    )
}
