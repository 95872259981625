import React, { useContext } from 'react'
import { SkycityContext } from '../../lib/SkycityProvider'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import { List, ListItem } from '@react/react-spectrum/List'
import Heading from '@react/react-spectrum/Heading'
import Delete from '@react/react-spectrum/Icon/Delete'
import Button from '@react/react-spectrum/Button'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'

export default function SortableContent (props) {
    const {
        dispatch
    } = useContext(SkycityContext)
    const accelerateBucketMatch = /https:\/\/(.*)\.s3-accelerate\.amazonaws\.com\/(.*?)\/(.*?)\/(.*?)\?.*Expires=(\d*)(.*)/
    const items = props.files.map(file => {
        return file.path.match(accelerateBucketMatch) ? file.name : file.path
    })

    function onSortEnd ({ oldIndex, newIndex }) {
        const newFile = arrayMove(items, oldIndex, newIndex)
        console.log(`newFile: ${newFile}`)

        const newFiles = newFile.map(path => {
            return props.files.find(originalFile => originalFile.path === path)
        })

        dispatch({ type: props.actionType, payload: { wait: false, files: newFiles, file: { progress: 0, name: '' } } })
    };

    function deleteContent (e) {
        const newFiles = props.files.filter(file => {
            const removeNum = e.split(' - ')[1]
            return file.path !== removeNum
        })
        dispatch({ type: props.actionType, payload: { wait: false, files: newFiles, file: { progress: 0, name: '' } } })
    }

    function getIndexNum (num) {
        return num + 1
    }
    const SortableItem = SortableElement(({ value }) => <ListItem>
        <div className="adobe-skycity-sortable-content">
            <div className="adobe-skycity-sortable-content-name">{value}</div>
            <Button variant="tool" icon={<Delete size="XS"/>} onClick={ e => deleteContent(value) } />
        </div>
    </ListItem>)
    const SortableList = SortableContainer(({ items }) => {
        const sortableItems = items.map((value, index) => {
            return <SortableItem key={value} index={index} value={`${getIndexNum(index)} - ${value}`} />
        })
        return <div>
            <Heading variant="subtitle2">{__('xml_reorder_preset')}</Heading>
            <List className="adobe-skycity-sortable">{sortableItems}</List>
        </div>
    })

    return <SortableList items={items} onSortEnd={onSortEnd} />
}
