import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import axios from 'axios'

import { ssApiSyncCall } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders
} from '../../lib/skycity.js'

import {
    exploreIndexDocument,
    exploreHomeDirectory,
    uploadFile,
    retrieveRendition

} from '../../lib/acpApi.js'

import SenseiParams from './SenseiParams'

const uuid = require('uuid/v4')
const LinkHeader = require('http-link-header')

export default function Operation (files) {
    const {
        inputFiles,
        inputSecondFiles,
        inputThirdFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    const [params, setParams] = useState({
        selectSubject: true,
        selectSky: false,
        selectSkin: false,
        selectPeople: false,
        depthMap: false,
        depthRefinement: false,
        nearFocus: 0,
        farFocus: 0
    })

    function formatParams (rawParams) {
        const requestTypes = Object.keys(rawParams).filter(key =>
            ['selectSubject', 'selectSky', 'selectSkin', 'selectPeople', 'depthMap', 'depthRefinement'].includes(key) && rawParams[key]
        )

        if (requestTypes.includes('depthRefinement')) {
            const focalRange = {
                near_focus: Number.parseFloat(rawParams.nearFocus),
                far_focus: Number.parseFloat(rawParams.farFocus)
            }
            console.log(focalRange)
            if (focalRange.near_focus > 0 || focalRange.far_focus > 0) {
                console.log('focal range is set')
                return {
                    request_types: requestTypes,
                    focal_range: focalRange
                }
            }
        }

        return {
            request_types: requestTypes
        }
    }

    async function start (curFile, secondFile, thirdFile) {
        const files = []
        // replaced with presignedUrl
        const imgFilePath = curFile.path

        const formattedParams = formatParams(params)
        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'ACR Masking',
                'sensei:invocation_mode': 'synchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Classification:autocrop:Service-e02a518da69e44c7a90ea5f1006a02bd'
                        },
                        'sensei:params': formattedParams,
                        'sensei:inputs': {
                            image: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': imgFilePath
                            }
                        },
                        'sensei:outputs': {
                            masks: {
                                'dc:format': 'image/jpeg',
                                'sensei:multipart_field_name': 'masks'
                            },
                            metadata: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'metadata'
                            }
                        }
                    }
                ]
            }
        }

        if (secondFile) {
            const depthMapPath = secondFile.path
            codePayload.contentAnalyzerRequests['sensei:engines'][0]['sensei:inputs'].depth_map = {
                'dc:format': checkFileType(secondFile.type),
                'sensei:repoType': 'HTTP',
                'repo:path': depthMapPath
            }
        }

        if (thirdFile) {
            const embeddedDepthMapPath = thirdFile.path
            codePayload.contentAnalyzerRequests['sensei:engines'][0]['sensei:inputs'].embedded_depth_map = {
                'dc:format': checkFileType(thirdFile.type),
                'sensei:repoType': 'HTTP',
                'repo:path': embeddedDepthMapPath
            }
        }

        if (formattedParams.request_types.includes('depthMap')) {
            codePayload.contentAnalyzerRequests['sensei:engines'][0]['sensei:outputs'].focal_range = {
                'dc:format': 'application/json',
                'sensei:multipart_field_name': 'focal_range'
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        const responseBody = await ssApiSyncCall(config)
        const contentAnalyzerResponse = responseBody.get('contentAnalyzerResponse')
        const senseiMetadata = JSON.parse(contentAnalyzerResponse)
        const masks = senseiMetadata.statuses[0].invocations[0]['sensei:outputs'].masks
        const metadata = JSON.parse(responseBody.get('metadata'))
        let focalRange
        if (formattedParams.request_types.includes('depthMap')) {
            focalRange = JSON.parse(responseBody.get('focal_range'))
        }

        // 1. Explore the Index Document and get the Primary URL of the home Directory
        const homeDirectoryPrimaryUrl = await exploreIndexDocument()

        // 2. Explore the home Directory and get the Create URL of the Cloud Documents Directory
        const exploreHomeDirectoryResponse = await exploreHomeDirectory(
            homeDirectoryPrimaryUrl
        )
        const cloudDocumentsCreateUrl = exploreHomeDirectoryResponse.createLink
        // upload masks
        for (let i = 0; i < masks.length; ++i) {
            const output = responseBody.get(masks[i]['sensei:multipart_field_name'])
            const blob = output.slice(0, output.size, 'image/jpeg')
            const outputFileName = `acrmasking_mask_${uuid()}_${curFile.shortName}.jpeg`
            const newFile = new File([blob], outputFileName, { type: 'image/jpeg' })
            const outputHref = `${getTempDirectory()}/${outputFileName}`
            const fileUploaded = await uploadFile(
                cloudDocumentsCreateUrl,
                newFile
            )
            const uploadlinks = LinkHeader.parse(fileUploaded.headers.get('link'))
            const renditionUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/rendition'))[0].uri
            const primaryUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/primary'))[0].uri
            const largeRendition = await retrieveRendition(
                renditionUrl,
                300,
                'image/jpeg'
            )
            files.push({
                inputFile: curFile,
                name: getFilename(outputHref),
                shortName: getFilenameWithoutExt(outputHref),
                path: outputHref,
                outputThumbnail: largeRendition,
                output: largeRendition,
                outputToDownload: `${getEncodedURI(primaryUrl)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
            })
        }

        let response = { metadata }

        if (focalRange) {
            response = {
                ...response,
                focalRange
            }
        }

        dispatch({
            type: 'skycity-success',
            payload: {
                files: files,
                response: response,
                loading: false,
                wait: false
            }
        })
    }

    async function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            const funcArray = []
            inputFiles.forEach(inputFile => {
                if (inputSecondFiles.length > 0) {
                    inputSecondFiles.forEach(secondFile => {
                        if (inputThirdFiles.length > 0) {
                            inputThirdFiles.forEach(thirdFile => {
                                funcArray.push(start(inputFile, secondFile, thirdFile))
                            })
                        } else {
                            funcArray.push(start(inputFile, secondFile))
                        }
                    })
                } else {
                    funcArray.push(start(inputFile))
                }
            })
            await axios.all(funcArray)
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function checkFileType (type) {
        if (type === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    try {
        return <div>
            <SenseiParams params={params} setParams={setParams} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
