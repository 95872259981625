import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import axios from 'axios'

import { apiCall, getStatus } from '../../lib/cisApi.js'
import {
    getHeaders,
    getStorage,
    getOptionsOutputImagecutout,
    getRenditionCreateWithUtilsStorage,
    getFileType,
    getOutputFileName
} from '../../lib/skycity.js'

export default function Operation (files) {
    const {
        inputFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    async function start (curFile) {
        /* *********************************** */
        const codePayload = {
            input: {
                storage: getStorage(curFile),
                href: curFile.path
            },
            output: await getOptionsOutputImagecutout(curFile)
        }
        const config = {
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_SENSEI}/cutout`,
            method: 'POST',
            timeout: 3000000000,
            headers: getHeaders(),
            payload: codePayload,
            body: JSON.stringify(codePayload)
        }
        /* *********************************** */

        dispatch({ type: 'set-codes', payload: { config: config } })

        apiCall(config, (id, json) => {
            if (id) {
                getStatus(id, config, async (res, error, errResponse) => {
                    if (error) {
                        errorOccured(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.status === 'failed') {
                        const errMsg = res.errors ? `${id} - ${JSON.stringify(res.errors, null, 2)}` : `ERROR with ${config.url}`
                        errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                    } else if (res && res.output && res.status === 'succeeded') {
                        // Skycity library to be udpated for Sensei response
                        if (files.find(file => file.inputFile === curFile)) {
                            return
                        }
                        const rendition = config.payload.output
                        const outputFile = {
                            href: rendition.getHref,
                            storage: 'external',
                            type: 'image/png'
                        }
                        await getRenditionCreateWithUtilsStorage(outputFile, 300, 0, (err, renditionSmall, renditionLarge) => {
                            if (err) {
                                console.log(`ERROR: ${JSON.stringify(err)}`)
                                error(`ERROR: ${JSON.stringify(err)}`, {
                                    timeout: 0
                                })
                            } else {
                                files.push({
                                    inputFile: curFile,
                                    name: getOutputFileName(curFile, rendition),
                                    shortName: getFileType('image/png'),
                                    path: rendition.href,
                                    outputThumbnail: renditionSmall,
                                    output: renditionLarge,
                                    outputToDownload: rendition.getHref
                                })
                                if (files.length === 1) {
                                    dispatch({
                                        type: 'skycity-success',
                                        payload: {
                                            files: files,
                                            selectedInputFile: files[0],
                                            loading: false,
                                            wait: false,
                                            response: res
                                        }
                                    })
                                }
                            }
                        })
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                funcArray.push(start(curFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
