import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import ACRPredictOptions from '../Shared/ACRPredictOptions'

import { apiCall, getStatus } from '../../lib/cisApi.js'
import { getFilename, getFilenameWithoutExt, getHeaders } from '../../lib/skycity.js'

import axios from 'axios'

// eslint-disable-next-line import/no-webpack-loader-syntax
import TiffToJPEGWorker from 'worker-loader!../Shared/ACRTiffToJPEGWorker'

export default function Operation (files) {
    const {
        inputFiles,
        errorOptions,
        dispatch,
        lrACRPredictOptions
    } = useContext(SkycityContext)

    function fileUpload (fileToUpload, url) {
        return new Promise((resolve, reject) => {
            const url = `${process.env.REACT_APP_CONFIG_ENDPOINT_UTILS}/storage?num=1`
            const config = {
                timeout: 90000,
                headers: getHeaders()
            }

            return axios.get(url, config).then((response, err) => {
                dispatch({ type: 'set-input-local', payload: { wait: true, files: inputFiles, file: { progress: 0, name: fileToUpload.name } } })
                const configS3PUT = {
                    headers: {
                        'Content-Type': 'application/octet-stream',
                        'Access-Control-Allow-Origin': '*'
                    },
                    onUploadProgress: (e) => {
                        const currentProgress = (parseInt(e.loaded) / parseInt(e.total) * 100).toFixed(0)
                        const curFile = {}
                        curFile.name = fileToUpload.name
                        curFile.progress = currentProgress
                        dispatch({ type: 'set-input-local', payload: { wait: true, files: inputFiles, file: { progress: currentProgress, name: fileToUpload.name } } })
                    },
                    timeout: 300000
                }
                axios.put(response.data.outputs.urls[0].put, fileToUpload, configS3PUT).then((res, err) => {
                    if (!err) {
                        resolve(response.data.outputs.urls[0].get)
                    }
                })
            })
        })
    }

    async function fileDownload (url) {
        const config = {
            headers: {
                'Content-Type': 'application/octet-stream',
                'Access-Control-Allow-Origin': '*'
            },
            timeout: 300000,
            responseType: 'arraybuffer'
        }
        return axios.get(url, config)
    }

    function downloadMask (mask) {
        const tiffToJPEGWorker = new TiffToJPEGWorker()
        return new Promise((resolve, reject) => {
            async function processMask () {
                try {
                    const rawImage = await fileDownload(mask)
                    tiffToJPEGWorker.onmessage = (base64StringOutput) => {
                        resolve(base64StringOutput.data)
                        tiffToJPEGWorker.terminate()
                    }
                    tiffToJPEGWorker.postMessage(rawImage.data)
                } catch (error) {
                    reject(error)
                }
            }
            processMask()
        })
    }

    function start (curFile) {
        fileUpload(curFile.file).then((getURL) => {
            const codePayload = {
                inputs: [
                    {
                        href: getURL,
                        storage: 'external'
                    }
                ],
                options: lrACRPredictOptions
            }
            const config = {
                url: `${process.env.REACT_APP_CONFIG_ENDPOINT_ACR}/predict`,
                method: 'POST',
                timeout: 90000,
                headers: getHeaders(),
                payload: codePayload,
                body: JSON.stringify(codePayload),
                localFilePath: curFile.path
            }
            apiCall(config, async (links, json) => {
                const id = links
                if (id) {
                    getStatus(id, config, async (res, error, errResponse) => {
                        dispatch({ type: 'set-codes', payload: { config: config } })
                        if (error) {
                            errorOccured(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                        } else if (res && res.status === 'failed') {
                            const errMsg = res.outputs._links.masks.map((maskType) => {
                                if (maskType.errors) {
                                    return ` ${maskType.maskType} { reason: "${maskType.errors.reason}", code: ${maskType.errors.code}}`
                                } else {
                                    return `ERROR with ${config.url}`
                                }
                            })
                            errorOccured(`${id} : ${errMsg}`, errResponse || res)
                        } else if (res && res.status === 'succeeded') {
                            let outputs = []
                            const downloadMasks = res.outputs._links.masks.map((maskType) => {
                                if (maskType.errors) {
                                    const errMsg = ` ${maskType.maskType} { reason: "${maskType.errors.reason}", code: ${maskType.errors.code}}`
                                    errorOccured(`${id} : ${errMsg}`, errResponse || res)
                                } else {
                                    return maskType.hrefs.map((maskObj) => {
                                        if (maskObj.maskDetected) {
                                            const mask = maskObj.href
                                            outputs = outputs.concat({
                                                inputFile: {
                                                    base64: curFile.base64
                                                },
                                                name: getFilename(mask),
                                                shortName: getFilenameWithoutExt(mask),
                                                path: mask,
                                                outputToDownload: mask
                                            })
                                            return downloadMask(mask)
                                        }
                                    })
                                }
                            })
                            const downloadMasksFiltered = await Promise.all(downloadMasks.flat())
                            const masks = downloadMasksFiltered.filter(mask => mask !== undefined)
                            for (let i = 0; i < masks.length; i++) {
                                outputs[i] = {
                                    ...outputs[i],
                                    ...{
                                        output: masks[i],
                                        outputThumbnail: masks[i]
                                    }
                                }
                            }
                            dispatch({
                                type: 'skycity-success',
                                payload: {
                                    files: outputs,
                                    selectedInputFile: outputs[0],
                                    showLayerInfoPane: true,
                                    loading: false,
                                    wait: false,
                                    response: res
                                }
                            })
                        }
                    })
                } else {
                    const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                    errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
                }
            })
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(inputFile => {
                funcArray.push(start(inputFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0 && lrACRPredictOptions.requestTypes && lrACRPredictOptions.requestTypes.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <ACRPredictOptions />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
