import { __ } from 'i18n-for-browser'
import '../config/i18n'
import { error } from '@react/react-spectrum/Toast'
import axios from 'axios'
import {
    getHeaders
} from './skycity'

export async function generatePresignedUrlsFromUtils (num) {
    try {
        const url = `${process.env.REACT_APP_CONFIG_ENDPOINT_UTILS}/storage?num=${num}`
        const config = {
            timeout: 90000,
            headers: getHeaders()
        }
        const response = await axios.get(url, config)
        return response
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
        return e
    }
}
