import React from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import { error } from '@react/react-spectrum/Toast'
import Checkbox from '@react/react-spectrum/Checkbox'
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger'
import Tooltip from '@react/react-spectrum/Tooltip'

export default function SenseiParams (props) {
    function toggleRescale () {
        const rescale = !props.params.rescale
        props.setParams({ ...props.params, rescale: rescale })
    }

    try {
        return <div className="adobe-skycity-output-options">
            <Well>
                <Heading variant="pageTitle">{__('skycity_comp_title_output_options')}</Heading>
                <React.Fragment>
                    <Heading className="adobe-skycity-subtitle1" variant="subtitle1">Sensei Params</Heading>
                    <Well>
                        <div className="adobe-skycity-options-col">
                            <div className="adobe-skycity-option">
                                <OverlayTrigger placement="right">
                                    <Checkbox value={props.params.rescale}
                                        defaultChecked={props.params.rescale}
                                        onChange={ e => toggleRescale()}
                                        label={__('output_option_tooltip_rescale_label_domo')}>
                                    </Checkbox>
                                    <Tooltip variant="info">
                                        {__('output_option_tooltip_rescale_domo')}
                                    </Tooltip>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </Well>
                </React.Fragment>
            </Well>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
