import React from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import { error } from '@react/react-spectrum/Toast'

import Checkbox from '@react/react-spectrum/Checkbox'
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger'
import Tooltip from '@react/react-spectrum/Tooltip'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import Textfield from '@react/react-spectrum/Textfield'

export default function SenseiParams (props) {
    function setText (name, value) {
        const variable = {}
        variable[name] = value
        props.setParams({ ...props.params, ...variable })
    }

    function toggleHumanModel () {
        const humanModel = !props.params.human_model
        props.setParams({ ...props.params, human_model: humanModel })
    }

    try {
        return <div className="adobe-skycity-output-options">
            <Well>
                <React.Fragment>
                    <Heading className="adobe-skycity-subtitle1" variant="subtitle1">Sensei Params</Heading>
                    <Well>
                        <div className="adobe-skycity-options-col">
                            <div className="adobe-skycity-option">
                                <OverlayTrigger placement="right">
                                    <Checkbox value={props.params.human_model}
                                        defaultChecked={props.params.human_model}
                                        onChange={ e => toggleHumanModel()}
                                        label="Use Human Model?">
                                    </Checkbox>
                                    <Tooltip variant="info">
                                        {__('output_option_tooltip_humanmodel_shadowgenerator')}
                                    </Tooltip>
                                </OverlayTrigger>
                            </div>
                            <div className="adobe-skycity-shadowgenerator-option">
                                <FieldLabel className="adobe-skycity-options-field" label="Shadow Brightness" labelFor="idShadowBrightness" position="left">
                                    <OverlayTrigger placement="right">
                                        <Textfield
                                            label="Shadow Brightness"
                                            defaultValue={0.5}
                                            value={props.params.shadow_brightness}
                                            onChange={e => setText('shadow_brightness', e)}
                                            id="idShadowBrightness"
                                        />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_shadowbrightness_shadowgenerator')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                        </div>
                    </Well>
                </React.Fragment>
            </Well>
            <Well>
                <React.Fragment>
                    <Heading className="adobe-skycity-subtitle1" variant="subtitle1">Input Params (light_map_in)</Heading>
                    <Well>
                        <div className="adobe-skycity-options-col">
                            <div className="adobe-skycity-shadowgenerator-option">
                                <FieldLabel className="adobe-skycity-options-field" label="x" labelFor="idLightMapx" position="left">
                                    <OverlayTrigger placement="right">
                                        <Textfield
                                            label="x"
                                            defaultValue={0}
                                            value={props.params.x}
                                            onChange={e => setText('x', e)}
                                            id="idLightMapx"
                                        />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_lightmapx_shadowgenerator')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-shadowgenerator-option">
                                <FieldLabel className="adobe-skycity-options-field" label="y" labelFor="idLightMapy" position="left">
                                    <OverlayTrigger placement="right">
                                        <Textfield
                                            label="y"
                                            defaultValue={1.0}
                                            value={props.params.y}
                                            onChange={e => setText('y', e)}
                                            id="idLightMapy"
                                        />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_lightmapy_shadowgenerator')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-shadowgenerator-option">
                                <FieldLabel className="adobe-skycity-options-field" label="radius" labelFor="idLightMapradius" position="left">
                                    <OverlayTrigger placement="right">
                                        <Textfield
                                            label="radius"
                                            defaultValue={0.5}
                                            value={props.params.radius}
                                            onChange={e => setText('radius', e)}
                                            id="idLightMapradius"
                                        />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_lightmapradius_shadowgenerator')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                        </div>
                    </Well>
                </React.Fragment>
            </Well>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
