import React from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import { error } from '@react/react-spectrum/Toast'

import Checkbox from '@react/react-spectrum/Checkbox'
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger'
import Tooltip from '@react/react-spectrum/Tooltip'
import Select from '@react/react-spectrum/Select'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import Textfield from '@react/react-spectrum/Textfield'

export default function SenseiParams (props) {
    function setOption (field, selected) {
        const options = {}
        options[field] = props.params[field].map(option => {
            option.selected = false
            if (option.value === selected) {
                option.selected = true
            }
            return option
        })

        props.setParams({ ...props.params, ...options })
    }

    function setText (name, value) {
        const slider = {}
        slider[name] = value
        props.setParams({ ...props.params, ...slider })
    }

    function toggleRefine () {
        const refine = !props.params.refine
        props.setParams({ ...props.params, refine: refine })
    }

    try {
        return <div className="adobe-skycity-output-options">
            <Well>
                <Heading variant="pageTitle">{__('skycity_comp_title_output_options')}</Heading>
                <React.Fragment>
                    <Heading className="adobe-skycity-subtitle1" variant="subtitle1">Sensei Params</Heading>
                    <Well>
                        <div className="adobe-skycity-options-col">
                            <div className="adobe-skycity-option">
                                <FieldLabel className="adobe-skycity-options-field" label="Output Form" labelFor="idOutputForm" position="left">
                                    <OverlayTrigger placement="right">
                                        <Select
                                            options={props.params.outputForm}
                                            onChange={e => setOption('outputForm', e)}
                                            id="idOutputForm" />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_outputform_depthestimation')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-option">
                                <FieldLabel className="adobe-skycity-options-field" label="Output Mode" labelFor="idOutputMode" position="left">
                                    <OverlayTrigger placement="right">
                                        <Select
                                            options={props.params.outputMode}
                                            onChange={e => setOption('outputMode', e)}
                                            id="idOutputMode" />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_outputmode_depthestimation')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-depthlab-option">
                                <FieldLabel className="adobe-skycity-options-field" label="Input Dimension" labelFor="idInputDimension" position="left">
                                    <OverlayTrigger placement="right">
                                        <Textfield
                                            label="Input dimension"
                                            defaultValue={640}
                                            value={props.params.inputDim}
                                            onChange={e => setText('inputDim', e)}
                                            id="idInputDimension"
                                        />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_inputdimension_depthestimation')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-option">
                                <OverlayTrigger placement="right">
                                    <Checkbox value={props.params.refine}
                                        defaultChecked={props.params.refine}
                                        onChange={ e => toggleRefine()}
                                        label="Perform Refinement">
                                    </Checkbox>
                                    <Tooltip variant="info">
                                        {__('output_option_tooltip_refine_depthestimation')}
                                    </Tooltip>
                                </OverlayTrigger>
                            </div>
                            <div className="adobe-skycity-depthlab-option">
                                <FieldLabel className="adobe-skycity-options-field" label="Refine Dimension" labelFor="idRefineDimension" position="left">
                                    <OverlayTrigger placement="right">
                                        <Textfield
                                            label="Refine dimension"
                                            disabled={!props.params.refine}
                                            defaultValue={640}
                                            value={props.params.refineDim}
                                            onChange={e => setText('refineDim', e)}
                                            id="idRefineDimension"
                                        />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_refinedimension_depthestimation')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                        </div>
                    </Well>
                </React.Fragment>
            </Well>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
