import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Checkbox from '@react/react-spectrum/Checkbox'
import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import Select from '@react/react-spectrum/Select'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import NumberInput from '@react/react-spectrum/NumberInput'
import Button from '@react/react-spectrum/Button'
import AddCircle from '@react/react-spectrum/Icon/AddCircle'
import Delete from '@react/react-spectrum/Icon/Delete'

const uuid = require('uuid/v4')

export default function LayerOptions (props) {
    // const stepNum = props.stepNum ? `${props.stepNum} ` : '';
    const {
        adjustmentLayers,
        layerOptions,
        dispatch
    } = useContext(SkycityContext)

    function changeLayerSelection (e, curOption) {
        const newLayerOptions = layerOptions.map(curLayerOption => {
            if (curLayerOption.id === curOption.id) {
                curLayerOption.selected = e
            }
            return curLayerOption
        })
        dispatch({ type: 'set-layerOptions', payload: { layerOptions: newLayerOptions } })
    }

    function deleteLayerOption (e, curOption) {
        const newLayerOptions = layerOptions.filter(curLayerOption => curLayerOption.id !== curOption.id)
        dispatch({ type: 'set-layerOptions', payload: { layerOptions: newLayerOptions } })
    }

    function insertLayer (e) {
        let newLayerOption = {}
        switch (e) {
        case 'brightnessContrast':
            newLayerOption = JSON.parse(JSON.stringify(adjustmentLayers.brightnessContrast))
            break
        case 'exposure':
            newLayerOption = JSON.parse(JSON.stringify(adjustmentLayers.exposure))
            break
        case 'colorBalance':
            newLayerOption = JSON.parse(JSON.stringify(adjustmentLayers.colorBalance))
            break
        case 'hueSaturation':
            newLayerOption = JSON.parse(JSON.stringify(adjustmentLayers.hueSaturation))
            break
        default:
        }
        newLayerOption.id = uuid()
        const newLayerOptions = layerOptions.map(curLayerOption => {
            return curLayerOption
        })
        newLayerOptions.push(newLayerOption)
        dispatch({ type: 'set-layerOptions', payload: { layerOptions: newLayerOptions } })
    }

    function changeValue (e, layer, curLayer, curColor) {
        const newLayerOptions = layerOptions.map(curLayerOption => {
            if (curLayerOption.id === layer.id) {
                switch (layer.type) {
                case 'brightnessContrast':
                case 'exposure':
                    curLayerOption.props.find(prop => prop.type === curLayer.type).value = parseFloat(e.target.value)
                    break
                case 'colorBalance':
                    if (curLayer.field === 'checkbox') {
                        curLayerOption.props.find(prop => prop.type === curLayer.type).value = (curLayer.field === 'checkbox') ? e : e.target.value
                        break
                    } else { // array, colors
                        curLayerOption.props.find(prop => prop.type === curLayer.type).colors.find(color => color.type === curColor.type).value = parseFloat(e.target.value)
                    }
                    break
                case 'hueSaturation':
                    curLayerOption.channels.find(channel => channel.type === curLayer.type).value = (curLayer.field === 'checkbox') ? e : e.target.value
                    break
                default:
                }
            }
            return curLayerOption
        })
        dispatch({ type: 'set-layerOptions', payload: { layerOptions: newLayerOptions } })
    }

    function getProps (layerOption, props) {
        const forms = props.map(prop => {
            switch (prop.field) {
            case 'number':
                return <FieldLabel className="adobe-skycity-options-field" label={`${prop.label} (${prop.min} - ${prop.max})`} position="top">
                    <NumberInput
                        id={layerOption.id}
                        disabled={!layerOption.selected}
                        min={prop.min}
                        max={prop.max}
                        step={prop.step}
                        onBlur={e => changeValue(e, layerOption, prop)}
                        defaultValue={prop.value} />
                </FieldLabel>
            case 'array': {
                const colorForms = prop.colors.map(color => {
                    return <FieldLabel key={layerOption.id} className="adobe-skycity-options-field" label={`${color.label} (${color.min} - ${color.max})`} position="top">
                        <NumberInput
                            id={layerOption.id}
                            disabled={!layerOption.selected}
                            min={color.min}
                            max={color.max}
                            step={color.step}
                            onBlur={e => changeValue(e, layerOption, prop, color)}
                            defaultValue={color.value} />
                    </FieldLabel>
                })
                return <FieldLabel className="adobe-skycity-options-field" label={prop.label} position="top">{colorForms}</FieldLabel>
            }
            case 'select':
                return <FieldLabel className="adobe-skycity-options-field" label={prop.label} labelFor={prop.id} position="top">
                    <Select
                        id={prop.id}
                        disabled={!prop.selected}
                        options={prop.options}
                        defaultValue={getDefaultItem(prop.options)} />
                </FieldLabel>
            case 'checkbox':
                return <FieldLabel className="adobe-skycity-options-field" label={prop.label} labelFor={prop.id} position="top">
                    <Checkbox value={prop.type}
                        defaultChecked={prop.value}
                        id={prop.id}
                        onChange={ e => changeValue(e, layerOption, prop)}>{prop.label}</Checkbox>
                </FieldLabel>
            default:
                return null
            }
        })
        return <React.Fragment>{forms}</React.Fragment>
    }

    function getDefaultItem (items) {
        const selected = items.find(item => item.selected === true)
        return selected.value
    }

    function getLayerOptions () {
        if (!layerOptions || layerOptions.length === 0) {
            return null
        }
        const layerOptionsComp = layerOptions.map(layerOption => {
            let layerProps = null
            switch (layerOption.type) {
            case 'brightnessContrast':
            case 'exposure':
            case 'colorBalance':
                layerProps = layerOption.props
                break
            case 'hueSaturation':
                layerProps = layerOption.channels
                break
            default:
                break
            }
            if (layerProps) {
                return <Well key={layerOption.id}>
                    <div className="adobe-skycity-options" key={layerOption.id} title={`ID: ${layerOption.id}`}>
                        <div className="adobe-skycity-option">
                            <div className="adobe-skycity-options-field">
                                <Checkbox value={layerOption.type}
                                    defaultChecked={layerOption.selected}
                                    id={layerOption.id}
                                    onChange={ e => changeLayerSelection(e, layerOption)}>{layerOption.label}</Checkbox>
                            </div>
                            {getProps(layerOption, layerProps)}
                        </div>
                        <div className="adobe-skycity-options-field">
                            <Button variant="tool" icon={<Delete size="XS"/>} onClick={ e => deleteLayerOption(e, layerOption) } />
                        </div>
                    </div>
                </Well>
            }
            return null
        })
        return layerOptionsComp
    }

    return <div className="adobe-skycity-output-options">
        <Well>
            <Heading variant="pageTitle">{__('skycity_comp_title_layer_options')}</Heading>
            <br />
            <Button variant="action" value="brightnessContrast" icon={<AddCircle />} onClick={ e => insertLayer('brightnessContrast') }>Brightness Contrast</Button>
            <Button variant="action" value="exposure" icon={<AddCircle />} onClick={ e => insertLayer('exposure') }>Exposure</Button>
            <Button variant="action" value="hueSaturation" icon={<AddCircle />} onClick={ e => insertLayer('hueSaturation') }>Hue/Saturation</Button>
            <Button variant="action" value="colorBalance" icon={<AddCircle />} onClick={ e => insertLayer('colorBalance') }>Color Balance</Button>
            <br /><br />
            <div className="adobe-skycity-output-options-file-format">
                {getLayerOptions()}
            </div>
        </Well>
    </div>
}
