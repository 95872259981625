import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import Wait from '@react/react-spectrum/Wait'
import Operation from './Operation'
import SelectInput from '../Shared/SelectInput'
import FeatureNav from '../Shared/FeatureNav'
import Step from '../Shared/Step'
import Output from '../Shared/Output'

export default function Main () {
    const {
        inputFiles,
        inputFile,
        inputSecondFiles,
        inputSecondFile,
        outputFiles,
        response,
        codes,
        loading,
        error,
        errMsg,
        errMsgSideBySide,
        errorSideBySide,
        responseSideBySide,
        codesSideBySide
    } = useContext(SkycityContext)

    const splitViewOutputFiles = outputFiles.filter(output => output.previewSplitView === true)
    const sideBySideOutputFiles = outputFiles.filter(output => output.previewSplitView === false)

    let outputElement = <Output outputFiles={splitViewOutputFiles} response={response} codes={codes} errMsg={errMsg} error={error} />
    outputElement = <div className="adobe-skycity-outputs">
        <div className="adobe-skycity-output-pane"><h1>SplitView</h1><Output outputFiles={splitViewOutputFiles} response={response} errMsg={errMsg} codes={codes} error={error} /></div>
        <div className="adobe-skycity-output-pane"><h1>SideBySide</h1><Output outputFiles={sideBySideOutputFiles} response={responseSideBySide} errMsg={errMsgSideBySide} codes={codesSideBySide} error={errorSideBySide} /></div>
    </div>

    try {
        return <div className="adobe-skycity-main-pane">
            { loading && <div className="adobe-skycity-modal-loading">
                <Wait size="L" />
                <div>{__('pssocialmediarendition_loading_message')}</div>
            </div>}
            <div className="adobe-skycity-main">
                <div className="adobe-skycity-main-column">
                    <div className="adobe-skycity-main-feature">
                        <FeatureNav />
                        {/* **********************
                        Input
                        ********************** */}
                        <Step num={1} title={__('skycity_comp_title_input')} />
                        <SelectInput
                            storage={[]}
                            actionType="set-input"
                            title="Select the first image"
                            multiple={true}
                            files={inputFiles}
                            file={inputFile}
                            imageTypes="image/png,image/jpeg,image/tiff" />
                        <SelectInput
                            storage={[]}
                            actionType="set-input-second"
                            title="Select the final image"
                            multiple={true}
                            files={inputSecondFiles}
                            file={inputSecondFile}
                            imageTypes="image/png,image/jpeg,image/tiff" />

                        {/* **********************
                        Operation
                        ********************** */}
                        <Step num={2} title={__('skycity_comp_title_operation')} />
                        <Operation />
                        {/* **********************
                        Output
                        ********************** */}
                        <Step num={3} title={__('skycity_comp_title_output')} />
                        {outputElement}
                    </div>
                </div>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
