import React from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import { Tag } from '@react/react-spectrum/TagList'
// import Label from '@react/react-spectrum/Label';

export default function FeatureStatus ({ page }) {
    // const labelColor = "green";

    switch (page) {
    case 'psfileconversion':
    case 'psinsertlayer':
    case 'pssmartobject':
    case 'pssmartobjectV2':
    case 'pssmartobjectCsv':
    case 'psdocumentmanifest':
    case 'psedit':
    case 'pscrop':
    case 'psproductcrop':
    case 'pssocialmediarendition':
    case 'lrautostraighten':
    case 'lrmasking':
    case 'lrmaskingpredict':
    case 'lrautotone':
    case 'lrapplyedit':
    case 'lrapplypreset':
    case 'ssacrmasking':
    case 'ssdistractorice':
    case 'ssimagecutout':
    case 'ssimagecutoutonsensei':
    case 'ssimagecutoutonsenseiasync':
    case 'ssimagecutoutv5':
    case 'sscmganholefilling':
    case 'ssshadowdetection':
    case 'ssshadowremoval':
    case 'ssshadowgenerator':
    case 'sstrimapmatting':
    case 'ssuniversalrefinement':
    case 'ssmaskrefinement':
    case 'ssdepthestimation':
    case 'ssinteractiveselection':
    case 'ssobjectselection':
    case 'ssdomo':
    case 'ssbodyparser':
    case 'ssselectall':
    case 'ssmetacaf':
    case 'ssmetacafmodelcompare':
    case 'ssentitysegmentation':
    case 'sssegmentanything':
    case 'ssparametricharmonization':
    case 'sslayerscene':
    case 'ssllava':
        return <div className="adobe-skycity-api-status"><Tag>{__('feature_status_production')}</Tag></div>
        // return <div className="adobe-skycity-api-status"><Label variant={labelColor}>{__('feature_status_production')}</Label></div>;
    case 'psactions':
    case 'psartboard':
    case 'ssstuffsegmentation':
    case 'ssswappingautoencoder':
        return <div className="adobe-skycity-api-status"><Tag>{__('feature_status_prerelease')}</Tag></div>
        // return <div className="adobe-skycity-api-status"><Label variant={labelColor}>{__('feature_status_production')}</Label></div>;
    default:
        return null
    }
}
