import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import { ssApiSyncCall } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders
} from '../../lib/skycity.js'

import axios from 'axios'
import {
    exploreIndexDocument,
    exploreHomeDirectory,
    uploadFile,
    retrieveRendition

} from '../../lib/acpApi.js'
const LinkHeader = require('http-link-header')
const uuid = require('uuid/v4')

export default function Operation ({ posClicks, negClicks }) {
    const {
        errorOptions,
        dispatch,
        inputFiles,
        inputSecondFiles
    } = useContext(SkycityContext)

    async function start (curFile, secondFile) {
        const files = []
        //  replace with presignedUrl
        const imgFilePath = curFile.path
        const positiveClicks = posClicks.map(click => {
            return { x: click.x, y: click.y }
        })
        const negativeClicks = negClicks.map(click => {
            return { x: click.x, y: click.y }
        })
        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'InteractiveSelection',
                'sensei:invocation_mode': 'synchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Classification:autocrop:Service-3864cd0504f346a2a4d7d9311da81261'
                        },
                        'sensei:inputs': {
                            image_in: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': imgFilePath
                            },
                            positive_clicks: positiveClicks,
                            negative_clicks: negativeClicks
                        },
                        'sensei:outputs': {
                            mask_out: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'mask_out'
                            },
                            metadata_out: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'metadata_out'
                            }
                        }
                    }
                ]
            }
        }

        if (secondFile) {
            const secondFilePath = secondFile.path
            const type = secondFile
                ? checkFileType(secondFile.type)
                : 'image/png'

            codePayload.contentAnalyzerRequests['sensei:engines'][0]['sensei:inputs'].mask_in = {
                'dc:format': type,
                'sensei:repoType': 'HTTP',
                'repo:path': secondFilePath
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        const responseBody = await ssApiSyncCall(config)
        console.log(responseBody)
        const contentAnalyzerResponse = responseBody.get('contentAnalyzerResponse')
        const response = JSON.parse(responseBody.get('metadata_out'))
        response.sensei_metadata = JSON.parse(contentAnalyzerResponse)
        const responseImage = response.sensei_metadata.statuses[0].invocations[0]['sensei:outputs'].mask_out
        const outputFileName = `interactiveselection_${uuid()}_${curFile.shortName}.png`

        const img = responseBody.get(responseImage['sensei:multipart_field_name'])
        const blob = img.slice(0, img.size, 'image/png')
        const newFile = new File([blob], outputFileName, { type: 'image/png' })
        const outputHref = `${getTempDirectory()}/${outputFileName}`
        // 1. Explore the Index Document and get the Primary URL of the home Directory
        const homeDirectoryPrimaryUrl = await exploreIndexDocument()

        // 2. Explore the home Directory and get the Create URL of the Cloud Documents Directory
        const exploreHomeDirectoryResponse = await exploreHomeDirectory(
            homeDirectoryPrimaryUrl
        )
        const cloudDocumentsCreateUrl = exploreHomeDirectoryResponse.createLink
        const fileUploaded = await uploadFile(
            cloudDocumentsCreateUrl,
            newFile
        )
        const uploadlinks = LinkHeader.parse(fileUploaded.headers.get('link'))
        const primaryUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/primary'))[0].uri
        const renditionUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/rendition'))[0].uri
        const largeRendition = await retrieveRendition(
            renditionUrl,
            0,
            'image/png'
        )
        files.push({
            inputFile: curFile,
            name: getFilename(outputHref),
            shortName: getFilenameWithoutExt(outputHref),
            path: outputHref,
            outputThumbnail: largeRendition,
            output: largeRendition,
            outputToDownload: `${getEncodedURI(primaryUrl)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        })

        dispatch({
            type: 'skycity-success',
            payload: {
                files: files,
                response: response,
                loading: false,
                wait: false
            }
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            const funcArray = []
            inputFiles.forEach(inputFile => {
                if (inputSecondFiles.length > 0) {
                    inputSecondFiles.forEach(inputSecondFile => {
                        funcArray.push(start(inputFile, inputSecondFile))
                    })
                } else {
                    funcArray.push(start(inputFile))
                }
            })

            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    console.log(`err: ${err}`)
                    console.trace()
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                    dispatch({
                        type: 'sensei-end-wait'
                    })
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function checkFileType (type) {
        if (type === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    function errorOccured (errMsg, res) {
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
